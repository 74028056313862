import React, { memo, useMemo } from 'react';

import useWindowSize from '../../hooks/useWindowSize';
import { breakpoints } from '../../helpers/media';

type Props = {
  anchorId: string,
  offsetXl?: number,
  offsetDesktop?: number,
  offsetTablet?: number,
  offsetPhone?: number,
}

const Anchor = ({
  anchorId, offsetXl, offsetDesktop, offsetTablet, offsetPhone,
}: Props) => {
  const { width } = useWindowSize();
  const styles = {
    mobile: { top: `-${offsetPhone}px` },
    sm: { top: `-${offsetTablet}px` },
    md: { top: `-${offsetDesktop}px` },
    lg: { top: `-${offsetXl}px` },
  };
  const styleToUse = useMemo(() => {
    if (width >= breakpoints.lg) {
      return styles.lg;
    }
    if (width >= breakpoints.md) {
      return styles.md;
    }
    if (width >= breakpoints.sm) {
      return styles.sm;
    }
    return styles.mobile;
  }, [styles, width]);
  return (
    <div className="relative">
      <span
        style={styleToUse}
        className="absolute"
        id={anchorId}
      />
    </div>
  );
};

Anchor.defaultProps = {
  offsetXl: 200,
  offsetDesktop: 200,
  offsetTablet: 130,
  offsetPhone: 120,
};
export default memo(Anchor);
