import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { graphql } from 'gatsby';

import Layout from '../../elements/Layout';
import { debounce, lowercaseFrench, scrollIntoViewIfNeeded } from '../../../helpers/utils';

import PlacesNavigation from './PlacesNavigation';
import PlacesAlphabetically from './PlacesAlphabetically';
import PlacesSearch from './PlacesSearch';

type Props = {
  data: {
    allContentfulVille: {
      nodes: [{
        name: string,
        lieu: {
          name: string
        }
      }]
    },
    allContentfulEvenement: {
      nodes: [{
        id: string,
        place: {
          id: string,
          slug: string,
          name: string
        }
      }],
    }
  }
}

const Places = ({ data }: Props) => {
  const [isHidingEmptyPlaces, setIsHidingEmptyPlaces] = useState(true);
  const refBody = useRef(null);
  const [search, setSearch] = useState('');
  const { allContentfulVille, allContentfulEvenement } = data;
  const handleChangeSearch = useCallback((newSearch) => {
    if (refBody.current) {
      scrollIntoViewIfNeeded(refBody.current, true);
    }
    setSearch(newSearch);
  }, []);
  const handleChangeHidingEmptyPlaces = useCallback((val) => {
    setIsHidingEmptyPlaces(val);
  }, []);
  const eventsByPlace = useMemo(() => allContentfulEvenement.nodes.reduce((acc, event) => {
    if (!event?.place?.slug) return acc;
    if (!acc[event.place.slug]) {
      acc[event.place.slug] = [];
    }
    acc[event.place.slug].push(event);
    return acc;
  }, {}), [allContentfulEvenement]);
  const citiesAlphabetically = useMemo(() => {
    const lowercaseSearch = lowercaseFrench(search);
    let nbTotalEvents = 0;
    const cities = allContentfulVille.nodes.reduce((acc, city) => {
      const firstLetter = city.name.charAt(0).toUpperCase();
      let nbCityEvents = 0;
      let lieu = (city?.lieu || []).slice(0);
      if (lowercaseSearch && !(lowercaseFrench(city.name).indexOf(lowercaseSearch) > -1)) {
        lieu = lieu.filter((place) => lowercaseFrench(place.name).indexOf(lowercaseSearch) > -1);
      }
      if (lieu.length === 0) return acc;
      lieu = lieu
        .sort((lieuA, lieuB) => (lieuA.name <= lieuB.name ? -1 : 1))
        .map((place) => {
          const placeNbEvents = eventsByPlace[place.slug]?.length || 0;
          nbTotalEvents += placeNbEvents;
          nbCityEvents += placeNbEvents;
          return ({
            ...place,
            nbEvents: placeNbEvents,
          });
        });

      if (isHidingEmptyPlaces) {
        if (nbCityEvents === 0) return acc;
        lieu = lieu.filter((singleLieu) => singleLieu.nbEvents > 0);
      }

      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push({
        ...city,
        nbEvents: nbCityEvents,
        lieu,
      });
      return acc;
    }, {});
    return { cities, nbTotalEvents };
  }, [allContentfulVille.nodes, eventsByPlace, search, isHidingEmptyPlaces]);
  const clickableLetters = useMemo(() => Object
    .keys(citiesAlphabetically.cities)
    .filter((cities) => cities.length > 0), [citiesAlphabetically]);
  const throttledOnChange = useMemo(() => debounce(handleChangeSearch, 300), [handleChangeSearch]);
  const seo = useMemo(() => ({
    title: 'Les lieux - Dom\'Jazz',
    description: 'Découvrez tous les lieux des évènements Jazz en région Provence-Alpes-Côte d\'Azur et bien plus.',
    url: '/places/',
  }), []);
  return (
    <Layout seo={seo} showNavbar light>
      <div className="max-w-[1920px] w-full mx-auto
        px-6 pt-6 pb-12
        sm:px-12 sm:pt-8 sm:pb-20
        md:px-16 md:pt-20 md:pb-40
      "
      >
        <h1 className="h2 mb-6 text-primary">Les lieux</h1>
        <div className="invisible" ref={refBody} />
        <div className="flex flex-row items-start">
          <div className="sticky mt-20 top-14 z-10 w-[344px] pr-20 hidden lg:block">
            <PlacesSearch
              searchId="desktop"
              onChange={throttledOnChange}
              nbTotalEvents={citiesAlphabetically.nbTotalEvents}
              onChangeHidingEmptyPlaces={handleChangeHidingEmptyPlaces}
              isHidingEmptyPlaces={isHidingEmptyPlaces}
            />
          </div>
          <div className="w-full max-w-[622px]">
            <PlacesNavigation letters={clickableLetters} />
            <div className="lg:hidden">
              <PlacesSearch
                searchId="mobile"
                onChange={throttledOnChange}
                nbTotalEvents={citiesAlphabetically.nbTotalEvents}
                onChangeHidingEmptyPlaces={handleChangeHidingEmptyPlaces}
                isHidingEmptyPlaces={isHidingEmptyPlaces}
              />
            </div>
            <PlacesAlphabetically citiesAlphabetically={citiesAlphabetically.cities} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Places($currentDate: Date!) {
    allContentfulEvenement(filter: {
      event_date: { gte: $currentDate }
      status: { nin: ["Annulé", "Reporté"] }
    }) {
      totalCount
      nodes {
        id
        place {
          id
          slug
        }
      }
    }
    allContentfulVille(sort: { order: ASC, fields: name }) {
      nodes {
        id
        name
        slug
        lieu {
          id
          name
          slug
        }
      }
    }
  }

`;

export default Places;
