import React, { memo } from 'react';

type Props = {
  author: string,
  children: string,
}

const Quote = ({ author, children }: Props) => (
  <div className="text-left">
    <p className="text-lg leading-normal md:text-[33px] md:leading-normal">{children}</p>
    <p className="leading-[1.7] md:leading-[1.87] text-[15px] md:text-[24px]">
      —
      {' '}
      {author}
    </p>
  </div>
);

export default memo(Quote);
