import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { graphql, navigate } from 'gatsby';
import dayjs from 'dayjs';
import stickybits from 'stickybits';
import qs from 'qs';

import Layout from '../../elements/Layout/Layout';
import generateCalendarObjectFromDate from '../../../helpers/generateCalendarObjectFromDates';
import CalendarEvents from '../../elements/CalendarEvents';
import EventsFilters from '../../elements/EventsFilters/EventsFilters';
import SearchArtistsOrEvents from '../../elements/SearchArtistsOrEvents/SearchArtistsOrEvents';
import EventsList from '../../elements/EventsList/EventsList';
import { capitalizeFirstLetter, isBrowser } from '../../../helpers/utils';
import useWindowSize from '../../../hooks/useWindowSize';
import { mediaSizes } from '../../../helpers/media';

import NextMonth from './NextMonth';

type Props = {
  pageContext: {
    year: string,
    month: string,
    startDate: string,
    endDate: string,
    pathNextMonth: string,
    pathPreviousMonth: string,
  },
  data: {
    allContentfulEvenement: {
      edges: []
    }
  },
  location: {
    search: string,
    hash: string,
  }
}

const AgendaMonth = (props: Props) => {
  const { width } = useWindowSize();
  const { pageContext, data, location } = props;
  const [eventsView, setEventsView] = useState(null);
  const [filterByCity, setFilterByCity] = useState(() => {
    const { city } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    return city || 'all';
  });
  const {
    pathNextMonth, pathPreviousMonth, startDate, endDate, month, year,
  } = pageContext;
  const handleChangeEventsView = useCallback((newView) => {
    localStorage.setItem('event-view', newView);
    setEventsView(newView);
  }, []);
  const handleSelectCity = useCallback((newCity) => {
    navigate(
      `?city=${newCity}`,
      { replace: true },
    );
    setFilterByCity(newCity);
  }, []);

  const dStartDate = useMemo(() => dayjs(startDate), [startDate]);
  const days = useMemo(() => generateCalendarObjectFromDate(startDate, endDate),
    [startDate, endDate]);
  const eventsByDays = useMemo(() => {
    const daysWithEvents = { ...days };
    let nbEvents = 0;
    Object.keys(daysWithEvents).forEach((dayDate) => {
      daysWithEvents[dayDate].events = [];
    });
    data.allContentfulEvenement.edges.forEach(({ node }) => {
      if (!(filterByCity === 'all' || filterByCity === node.place?.city?.slug)) {
        return null;
      }
      const day = daysWithEvents[node.event_date];
      if (day) {
        day.events.push(node);
        nbEvents += 1;
      }
      return true;
    });
    return { totalEvents: nbEvents, events: Object.values(daysWithEvents) };
  }, [data, days, filterByCity]);
  const seo = useMemo(() => {
    const description = `Tous les évènements Jazz de ${dStartDate.format('MMMM YYYY')} en Provence-Alpes-Côte d'Azur`;
    return ({
      title: `Agenda ${dStartDate.format('MMMM YYYY')} - Dom'Jazz`,
      description,
      url: `/agenda/${year}/${month}/`,
    });
  }, [dStartDate, month, year]);
  useEffect(() => {
    let newEventView = 'calendar';
    if (isBrowser && typeof localStorage !== 'undefined') {
      newEventView = localStorage.getItem('event-view') || 'calendar';
    }
    setEventsView(newEventView);
    stickybits('#sticky-filters');
    if (isBrowser && location.hash) {
      // eslint-disable-next-line no-param-reassign,no-multi-assign,max-len
      Promise.all(Array.from(document.images).filter((img) => !img.complete).map((img) => new Promise((resolve) => { img.onload = img.onerror = resolve; }))).then(() => {
        const anchorEl = document.getElementById(location.hash.slice(1));
        if (anchorEl) {
          anchorEl.scrollIntoView(true);
        }
      });
    }
  }, [location.hash]);
  const urlNextMonth = useMemo(() => {
    if (!pathNextMonth) return null;
    return `${pathNextMonth}${filterByCity && filterByCity !== 'all' ? `?city=${filterByCity}` : ''}`;
  }, [filterByCity, pathNextMonth]);
  const urlPrevMonth = useMemo(() => {
    if (!pathPreviousMonth) return null;
    return `${pathPreviousMonth}${filterByCity && filterByCity !== 'all' ? `?city=${filterByCity}` : ''}`;
  }, [filterByCity, pathPreviousMonth]);
  return (
    <Layout seo={seo} showNavbar light>
      <div className="p-6 text-center flex flex-col items-center sm:text-left sm:p-12 md:px-12 lg:px-16 lg:pt-20 lg:pb-10 xl:pb-14
        max-w-[1920px] w-full mx-auto sm:items-start lg:flex-row lg:items-end "
      >
        <h1 className="text-2xl font-display sm:text-[42px] md:text-[46px] text-primary flex-1">
          {`L'Agenda ${pageContext.year}`}
        </h1>
        <div className="w-full max-w-[365px] mt-4 md:mt-6 sm:-ml-4 lg:m-0">
          <SearchArtistsOrEvents />
        </div>
      </div>
      <div className="z-40" id="sticky-filters">
        <EventsFilters
          currentMonth={month}
          currentYear={year}
          currentEventsView={eventsView}
          currentCity={filterByCity}
          pathNextMonth={urlNextMonth}
          pathPreviousMonth={urlPrevMonth}
          onChangeEventView={handleChangeEventsView}
          onSelectCity={handleSelectCity}
        />
      </div>
      <div className={!eventsView ? 'invisible' : ''}>
        {eventsByDays.totalEvents > 0
          ? (
            <>
              {eventsView === 'list' || width <= mediaSizes.desktop
                ? <EventsList month={month} events={eventsByDays.events} />
                : <CalendarEvents month={month} events={eventsByDays.events} />}
            </>
          )
          : (
            <div className="text-center text-[20px] px-8 pt-8 pb-10 sm:pb-20 md:pb-36 text-grey md:text-[28px]">
              Pas d&apos;évènements ce mois-ci
              {filterByCity !== 'all' ? (
                <span>
                  {' à '}
                  <span className="text-primary">
                    {capitalizeFirstLetter(filterByCity)}
                  </span>
                </span>
              ) : ''}
            </div>
          )}
      </div>
      {urlNextMonth && <NextMonth url={urlNextMonth} />}
    </Layout>
  );
};

export default AgendaMonth;

export const query = graphql`
  query ($startDate: Date!, $endDate: Date!) {
    allContentfulEvenement(
      sort: { order: DESC, fields: event_date }
      filter: { event_date: { gte: $startDate, lte: $endDate }}
    ) {
      edges {
        node {
          ...EventInfos
        }
      }
    }
  }
`;
