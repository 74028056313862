import { Link } from 'gatsby';
import styled from 'styled-components';

const BlankLink = styled(Link)`
  text-decoration: none;
  letter-spacing: 0;
  &:after {
    display: none;
  }
`;

export default BlankLink;
