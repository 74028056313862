import dayjs from 'dayjs';

export default function generateCalendarObjectFromDate(startDate, endDate) {
  const days = {};
  const dStartDate = dayjs(startDate);
  const month = dStartDate.month() + 1;
  const startCalendar = dStartDate.weekday(0);
  const endCalendar = dayjs(endDate).weekday(6);
  for (let currentDate = startCalendar.clone();
    currentDate.isSameOrBefore(endCalendar);
    currentDate = currentDate.add(1, 'day')
  ) {
    const dateFormatted = currentDate.format('YYYY-MM-DD');
    const isCurrentMonth = currentDate.month() + 1 === month;
    days[dateFormatted] = {
      dateFormatted,
      date: currentDate.clone(),
      isCurrentMonth,
      isToday: currentDate.isToday(),
      events: [],
    };
  }
  return days;
}
