import styled from 'styled-components';

import theme from '../../styles/theme';

const AWithButtonStyle = styled.a`
  display: inline-block;
  padding: 12px 24px;
  background: ${(p) => p.bgcolor};
  border: 2px solid ${(p) => p.bordercolor};
  font-size: 16px;
  line-height: 1.5;
  font-family: ${(p) => p.theme.fontFamilyDisplay};
  color: ${(p) => p.color};
  transition: 0.2s all ease-in;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.hovercolor};
    background-color: ${(p) => p.hoverbgcolor};
    border-color: ${(p) => p.hoverbordercolor};
  }
  &:after {
    display: none;
  }
`;

AWithButtonStyle.defaultProps = {
  color: 'white',
  bgcolor: theme.blueJazz,
  bordercolor: theme.blueJazz,
  hovercolor: 'white',
  hoverbgcolor: theme.midBlue,
  hoverbordercolor: theme.midBlue,
};

export const AWithButtonStyleWhite = styled(AWithButtonStyle)``;
AWithButtonStyleWhite.defaultProps = {
  color: theme.blueJazz,
  bordercolor: theme.blueJazz,
  bgcolor: 'white',
  hovercolor: theme.midBlue,
  hoverbordercolor: theme.midBlue,
  hoverbgcolor: 'white',
};

export default AWithButtonStyle;
