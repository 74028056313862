import React, { memo } from 'react';

import LogoSvg from '../../assets/images/svg/logo-domjazz.inline.svg';

type Props = {
  className: string,
}
const Logo = ({
  className,
}: Props) => (
  <div
    className="inline-flex flex-row items-center justify-center"
  >
    <LogoSvg className={className} />
  </div>
);

export default memo(Logo);
