import { createClient } from 'contentful';

import { CONTENTFUL_DELIVERY_TOKEN, CONTENTFUL_SPACE_ID } from '../constants/general';

const contentfulClient = createClient({
  space: CONTENTFUL_SPACE_ID,
  accessToken: CONTENTFUL_DELIVERY_TOKEN,
});

export const generateContentfulClient = (accessToken) => createClient({
  space: CONTENTFUL_SPACE_ID,
  accessToken,
  host: 'preview.contentful.com',
});

export default contentfulClient;
