import React, { memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import cs from 'classnames';

import { iconSuccess } from '../../ui/Icons';
import Content from '../../ui/Content';

import FormError from './FormError';

interface NewsletterProps {
  theme: "light" | "dark";
}
const Newsletter = ({ theme }: NewsletterProps) => {
  const {
    register, handleSubmit, formState: { errors }, watch,
  } = useForm();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handlePreventDefault = useCallback((e) => e.preventDefault(), []);
  const handleSubmitNewsletter = useCallback(async (values) => {
    setError(false);
    setIsLoading(true);
    try {
      await window
        .fetch('/api/subscribe', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(values),
        }).then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response;
        }).then((response) => response.json());
      setHasSubmitted(true);
    } catch (e) {
      console.error(e);
      setError('Désolé :(, une erreur est survenue, veuillez réssayer');
    }
    setIsLoading(false);
  }, []);
  const name = watch('name');
  return (
    <div
      className={cs('bg-light-grey-2 border text-primary', {
        'bg-light-grey-2 border text-primary': theme === 'light',
        'bg-primary text-white': theme === 'dark',
      })}
      id="newsletter"
    >
      <Content>
        <h2 className="h2 normal-case max-w-[1100px]">Toutes les actus Jazz, dans votre boite mail avec notre Newsletter !</h2>
        <div>
          {hasSubmitted
            ? (
              <div className="py-8 text-inherit flex justify-start text-left items-start sm:flex-row sm:items-center p">
                <span className="inline-flex text-success flex-row items-center justify-center mr-2">{iconSuccess}</span>
                <span>
                  Merci de nous suivre
                  {' '}
                  <span className={theme === 'light' ? 'text-primary' : 'text-white'}>{name}</span>
                  ,
                  {' '}
                  à bientôt sur le site!
                </span>
              </div>
            )
            : (
              <>
                <p className={cs('p leading-normal mt-8 max-w-[820px]', {
                  'text-black': theme === 'light',
                })}
                >
                  La meilleure façon de se tenir au courant des derniers évènements Jazz de la région
                  Provence-Alpes-Côte d&apos;Azur et bien plus !
                </p>
                <div className="max-w-[360px]">
                  <form onSubmit={handleSubmit(handleSubmitNewsletter)}>
                    <label className="mt-6 block" htmlFor="name">
                      <span className="block text-inherit mb-2 text-xs sm:text-[18px]">Votre nom complet</span>
                      <input
                        className="input text-base"
                        id="name"
                        type="text"
                        autoComplete="name"
                        {...register('name', { required: 'Veuillez rentrer votre nom complet' })}
                      />
                      <FormError error={errors.name} />
                    </label>
                    <label className="mt-4 block" htmlFor="email">
                      <span className="block text-inherit mb-2 text-xs sm:text-[18px]">Votre email</span>
                      <input
                        className="input text-base"
                        id="email"
                        type="email"
                        autoComplete="email"
                        {...register('email', {
                          required: 'Veuillez rentrer un e-mail',
                          pattern: {
                            value: /^\S+@\S+$/i, message: 'L\'email est incorrect',
                          },
                        })}
                      />
                      <FormError error={errors.email} />
                    </label>
                    <button
                      type="submit"
                      disabled={isLoading}
                      onSubmit={handlePreventDefault}
                      className={cs('btn mt-8 mb-4', {
                        'btn-transparent': theme === 'dark',
                      })}
                    >
                      {isLoading ? 'Inscription...' : 'S\'inscrire'}
                    </button>
                    {error && <FormError error={error} />}
                  </form>
                </div>
              </>
            )}
        </div>
      </Content>
    </div>
  );
};

export default memo(Newsletter);
