import React, { memo, useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import { generateContentfulClient } from '../../../helpers/contentfulClient';
import mapEntryToEvent from '../../../helpers/mapEntryToEvent';
import EventPresentation from '../../../components/templates/Event/EventPresentation';

type Props = {
  id: string,
  location: {
    pathname: string
  }
}

const PreviewEvenement = ({ location, id }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [event, setEvent] = useState(true);
  useEffect(() => {
    const fetchEvent = async (previewAccessToken) => {
      const previewClient = generateContentfulClient(previewAccessToken);
      try {
        const entry = await previewClient.getEntry(id, {
          include: '3',
        });
        if (entry) {
          setEvent(mapEntryToEvent(entry));
        }
      } catch (err) {
        // console.error(err);
      }
      setIsLoading(false);
    };

    const previewAccessToken = localStorage.getItem('preview-access-token');
    if (!previewAccessToken) {
      navigate(`/preview?return_url=${encodeURIComponent(location.pathname)}`);
      return false;
    }
    fetchEvent(previewAccessToken);
    return true;
  }, [location.pathname, id]);
  if (isLoading) {
    return <div>Chargement...</div>;
  }
  return (
    <EventPresentation isPreview event={event} />
  );
};

export default memo(PreviewEvenement);
