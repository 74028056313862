import React, { memo } from 'react';

import Footer from '../Footer';
import SEO from '../SEO';
import Header from '../Header/Header';
import Newsletter from '../Newsletter';

type Props = {
  seo: {
    title: string,
    description: string,
    image: string,
    url: string,
    article: boolean,
    externalImg: boolean,
    pageSchema: [],
  },
  showNavbar: boolean,
  children: any,
  light: boolean,
  minimalist: boolean,
  themeNewsletterEmail?: 'light' | 'dark',
}

const Layout = ({
  seo: {
    title, description, image, url, article, externalImg, pageSchema,
  }, showNavbar, children, light, minimalist, themeNewsletterEmail,
}: Props) => (
  <div className="min-h-screen flex flex-col">
    <SEO
      title={title}
      description={description}
      image={image}
      url={url}
      article={article}
      externalImg={externalImg}
      pageSchema={pageSchema}
    />
    {minimalist ? (
      <main className="flex-1">
        {children}
      </main>
    ) : (
      <>
        {showNavbar && <Header light={light} />}
        <main className="flex-1">
          {children}
        </main>
        <Newsletter theme={themeNewsletterEmail} />
        <Footer />
      </>
    )}
  </div>
);
Layout.defaultProps = {
  themeNewsletterEmail: 'light',
};

export default memo(Layout);
