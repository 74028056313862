import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import styled, { css } from 'styled-components';

import ButtonWithLinkStyle from '../../ui/ButtonWithLinkStyle';
import { debounce } from '../../../helpers/utils';

const ContainerContentClamp = styled.div`
`;
const Content = styled.div`
  position: relative;
  ${(p) => (p.showFullContent ? '' : `
    max-height: ${p.maxHeight}px;
    overflow: hidden;
    ${p.showViewMore ? `
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image : linear-gradient(to bottom, 
                          rgba(255,255,255, 0), 
                          rgba(255,255,255, 1) 90%);
        width: 100%;
        height: 4em;
      }
    ` : ''}
  `)}
`;

const ContainerButton = styled.div`
  margin-top: 12px;
  ${(p) => css`
    ${p.theme.media.tablet`
      margin-top: 12px;
    `}
    ${p.theme.media.phone`
      margin-top: 20px;
      button {
        font-size: 12px;
      }
    `}
  `}
`;

type Props = {
  className: string,
  children: any,
  maxHeight: number,
}

const ContentClamp = ({ className, children, maxHeight }: Props) => {
  const refContainerContent = useRef(null);
  const [showViewMore, setShowViewMore] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);

  const handleToggleShowFullContent = useCallback(() => setShowFullContent((old) => !old), []);

  useEffect(() => {
    const hasClamping = (el) => {
      const { scrollHeight } = el;
      return scrollHeight > maxHeight;
    };

    const checkButtonAvailability = () => {
      if (refContainerContent.current) {
        setShowViewMore(hasClamping(refContainerContent.current));
      }
    };

    const debouncedCheck = debounce(checkButtonAvailability, 50);
    checkButtonAvailability();
    window.addEventListener('resize', debouncedCheck);
    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [maxHeight]);
  return (
    <ContainerContentClamp className={className}>
      <Content
        ref={refContainerContent}
        showFullContent={showFullContent}
        showViewMore={showViewMore}
        maxHeight={maxHeight}
        className="content"
      >
        {children}
      </Content>
      {showViewMore
        && (
        <ContainerButton className="view-more">
          <ButtonWithLinkStyle
            onClick={handleToggleShowFullContent}
          >
            {showFullContent ? 'Voir moins' : 'Voir plus'}
          </ButtonWithLinkStyle>
        </ContainerButton>
        )}
    </ContainerContentClamp>
  );
};

export default memo(ContentClamp);
