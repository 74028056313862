import React, { memo } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  padding: 64px;
  max-width: 1920px;
  width: 100%;
  margin: auto;
  ${(p) => css`
    ${p.theme.media.tablet`
      padding: 48px;
    `}
    ${p.theme.media.phone`
      padding: 24px;
    `}
  `}
`;
const Title = styled.h2`
  font-family: ${(p) => p.theme.fontFamilyDisplay};
  font-size: 48px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: ${(p) => p.theme.blueJazz};
  margin-bottom: 48px;
  ${(p) => css`
    ${p.theme.media.xl`
      font-size: 36px;
    `}
    ${p.theme.media.tablet`
      font-size: 38px;
      margin-bottom: 32px;
    `}
    ${p.theme.media.phone`
      font-size: 30px;
      margin-bottom: 23px;
    `}
  `}
`;

const YoutubeContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin-bottom: 24px;
  &:after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }
  &:last-child {
    margin-bottom: 0;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

type Props = {
  youtubeVideos: [string],
}

const EventVideos = ({ youtubeVideos }: Props) => (
  <Container>
    <Title>Médias</Title>
    <div>
      {youtubeVideos.map((code) => (
        <YoutubeContainer key={code}>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe
            src={`https://www.youtube.com/embed/${code}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </YoutubeContainer>
      ))}
    </div>
  </Container>
);

export default memo(EventVideos);
