import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import { CONTENTFUL_SPACE_ID } from '../../../constants/general';

const Bg = styled.div`
  background: #f3f339;
`;
const Container = styled.div`
  padding: 16px 64px;
  max-width: 1920px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    margin-left: 18px;
    font-size: 14px;
  }
  ${(p) => css`
    ${p.theme.media.tablet`
      padding: 16px 48px;
    `}    
    ${p.theme.media.phone`
       padding: 16px 24px;
    `}
  `}
`;

type Props = {
  eventContentfulId: string,
}

const EventIsPreview = ({ eventContentfulId }: Props) => (
  <Bg>
    <Container>
      <span>
        Ceci est un aperçu d&apos;un évènement.
      </span>
      <a
        href={`https://app.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/entries/${eventContentfulId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Editer sur contentful
      </a>
    </Container>
  </Bg>
);

export default memo(EventIsPreview);
