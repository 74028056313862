import React, { memo } from 'react';

const Copyright = () => (
  <div className="bg-primary p-4">
    <div className="text-xs text-white text-center mx-auto max-w-[588px] w-full leading-normal">
      Association Dom’Jazz - Loi 1901 - Enregistrée sous le n° W831011459 Sous-Préfecture Draguignan
      <br />
      SIRET 893 567 677 00016
    </div>
  </div>
);

export default memo(Copyright);
