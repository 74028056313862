import React, { memo, useCallback, useMemo } from 'react';
import { createEvent } from 'ics';
import dayjs from 'dayjs';
import styled, { css } from 'styled-components';

import { downloadGeneratedFile } from '../../../helpers/utils';

const StyledButton = styled.button`
  border: none;
  text-align: left;
  padding: 0;
  text-transform: none;
  font-size: 16px;
  color: ${(p) => p.theme.blueJazz};
  background: transparent;
  font-weight: 500;
  margin-top: 10px;
  line-height: 18px;
  letter-spacing: 0;
  &:hover {
    text-decoration: underline;
    color: ${(p) => p.theme.blueJazz};
    background: transparent;
  }
  ${(p) => css`
    ${p.theme.media.phone`
      font-size: 13px;
    `}
  `}
`;

type Props = {
  event: {
    title: string,
    slug: string,
    event_date: string,
    ticketing_url: string,
    artists: [{
      name: string,
    }],
    entry_hours: {
      entryHours: [{
        startTime: string,
        endTime: string,
      }],
    },
    place: {
      address: string,
      name: string,
      geoAddress: {
        lat: number,
        lon: number,
      },
      city: {
        name: string
      }
    }
  },
}

const ButtonDownloadEventICSFile = ({
  event: {
    event_date: eventDate, artists, place, title, slug, entry_hours: entryHours, ticketing_url: ticketingUrl,
  },
}: Props) => {
  const dEventDate = useMemo(() => dayjs(eventDate), [eventDate]);
  const optionsEvent = useMemo(() => {
    const opts = {
      productId: 'domjazz',
      start: [dEventDate.year(), dEventDate.month() + 1, dEventDate.date()],
      title,
      status: 'TENTATIVE',
      url: `https://www.domjazz.com/event/${slug}`,
      categories: ['Jazz', 'Concert'],
      alarms: [{ action: 'display', description: 'Reminder', trigger: { hours: 1, minutes: 30, before: true } }],
    };
    if (entryHours) {
      const [firstEntryHour] = entryHours.entryHours;
      if (firstEntryHour) {
        if (firstEntryHour.startTime) {
          const dStart = dayjs(firstEntryHour.startTime);
          opts.start = [dStart.year(), dStart.month() + 1, dStart.date(), dStart.hour(), dStart.minute()];
        }
        if (firstEntryHour.endTime) {
          const dEnd = dayjs(firstEntryHour.endTime);
          opts.end = [dEnd.year(), dEnd.month() + 1, dEnd.date(), dEnd.hour(), dEnd.minute()];
        }
      }
    }
    if (!opts.start) {
      opts.start = [dEventDate.year(), dEventDate.month() + 1, dEventDate.date()];
    }
    if (!opts.end) {
      opts.duration = { hours: 1 };
    }
    const descriptionLines = [];
    if (artists?.length > 0) {
      const allArtists = artists && artists.map((artist) => artist.name).join(', ');
      descriptionLines.push(`Artiste${artists.length > 1 ? 's' : ''}: ${allArtists}.`);
    }
    if (place) {
      opts.location = place.address || place.city?.name;
      opts.geo = place.geoAddress;
      descriptionLines.push(`Lieu: ${place.name}`);
    }
    if (ticketingUrl) {
      descriptionLines.push('', `Billetterie: ${ticketingUrl}`, '');
    }
    descriptionLines.push('', 'Retrouvez d\'autres évènements sur www.domjazz.com');
    opts.description = descriptionLines.join('\n');
    return opts;
  }, [artists, dEventDate, entryHours, place, slug, ticketingUrl, title]);

  const handleClick = useCallback(() => {
    createEvent(optionsEvent, (error, value) => {
      if (error) {
        //        console.error(error);
        return;
      }
      downloadGeneratedFile(`${dEventDate.format('YYYY-MM-DD')}-${slug}.ics`, value);
    });
  }, [dEventDate, optionsEvent, slug]);
  return (
    <StyledButton type="button" onClick={handleClick}>
      + Ajouter au calendrier iOS / Google
    </StyledButton>
  );
};

export default memo(ButtonDownloadEventICSFile);
