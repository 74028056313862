import React from 'react';
import { Link } from 'gatsby';

import Logo from '../ui/Logo';
import getCurrentDateAgendaURL from '../../helpers/getCurrentDateAgendaURL';

const Links = () => (
  <div className="bg-primary min-h-screen">
    <div className="mx-auto max-w-[900px] w-full px-4 p-8 text-white flex flex-col justify-center items-center">
      <Link to="/">
        <Logo className="h-[48px]" />
      </Link>
      <Link to={getCurrentDateAgendaURL()} className="w-full mt-4">
        <button type="button" className="btn btn-secondary w-full">L&apos;Agenda</button>
      </Link>
      <a
        href="https://open.spotify.com/playlist/3h0BZ60APIa4tFf7mOfePI?si=c2c4eaa73ddc48d7"
        target="_blank"
        rel="noreferrer"
        className="w-full mt-4"
      >
        <button type="button" className="btn btn-secondary w-full">
          Découvertes Dom&apos;Jazz
          <br />
          Playlist Spotify
        </button>
      </a>
    </div>
  </div>
);

export default Links;
