import React, { memo, useCallback, useState } from 'react';

type Props = {
  name: string,
  label: string,
  defaultValue: string,
  options: [{
    value: string,
    label: string,
  }],
  onSelect: Function,
}

const ButtonMultiToggle = ({
  name, label, options, defaultValue, onSelect,
}: Props) => {
  const [value, setValue] = useState(defaultValue);
  const handleChange = useCallback((e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (onSelect) {
      onSelect(newValue);
    }
  }, [onSelect]);
  return (
    <div className="flex flex-row items-center">
      <div className="text-base leading-normal mr-2 ">{label}</div>
      {options.map((opt) => {
        const isChecked = value === opt.value;
        return (
          <div
            className="px-2 last:border-r-0 border-r border-primary flex flex-row items-center"
            key={opt.value}
          >
            <input
              className="hidden"
              type="radio"
              id={opt.value}
              name={name}
              value={opt.value}
              checked={isChecked}
              onChange={handleChange}
            />
            <label
              className={`text-base leading-normal 
              ${isChecked ? 'text-primary' : 'text-light-purple hover:underline cursor-pointer'}`}
              htmlFor={opt.value}
            >
              {opt.label}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default memo(ButtonMultiToggle);
