import React, { memo, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Select from '../../ui/Select';

type Props = {
  className: string,
  label?: string,
  currentCity?: string,
  onSelectCity: Function,
}

const CityFilter = ({
  className, label, currentCity, onSelectCity,
}: Props) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulVille(sort: { order: ASC, fields: name }) {
        edges {
          node {
            id
            slug
            name
          }
        }
      }
    }
  `);
  const options = useMemo(() => {
    const opts = data.allContentfulVille.edges.map(({ node }) => ({ value: node.slug, label: node.name }));
    opts.unshift({ label: 'Toutes', value: 'all' });
    return opts;
  },
  [data]);
  return (
    <Select className={className} label={label} options={options} defaultValue={currentCity} onSelect={onSelectCity} />
  );
};

CityFilter.defaultProps = {
  currentCity: 'all',
  label: 'Ville: ',
};
export default memo(CityFilter);
