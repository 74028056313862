import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';

import useClickOutside from '../../hooks/useClickOutside';
import scrollParentToChild from '../../helpers/scrollParentToChild';
import useWindowSize from '../../hooks/useWindowSize';

import { iconArrowLeft, iconSearch } from './Icons';

type ListOptionsProps = {
  options: [{
    type: string,
    value: any,
    label: any,
  }],
  activeOpt: number,
  onClickOpt: Function,
}
const ListOptions = ({ options, activeOpt, onClickOpt } : ListOptionsProps) => {
  const handleClickOpt = useCallback((opt) => () => onClickOpt(opt), [onClickOpt]);
  return options.map((opt, idx) => {
    const { type, label, value } = opt;
    if (type === 'not_found') {
      return (
        <div
          className="pt-6 px-4 pb-2 text-left text-purple"
          key="not_found"
        >
          {label}
        </div>
      );
    }
    if (type === 'divider') {
      return (
        <div
          className="text-left text-primary text-base font-display my-1 w-full pt-3 px-4 pb-1"
          key={label}
        >
          {label}
        </div>
      );
    }
    return (
      <button
        title={label}
        type="button"
        className={`opt-${idx} text-left truncate w-full py-1.5 px-4 relative transition text-sm hover:bg-light-grey
        ${activeOpt === idx ? 'text-primary bg-light-grey inside-border' : 'bg-white text-black'}
        `}
        key={value}
        onClick={handleClickOpt(opt)}
      >
        {label}
      </button>
    );
  });
};

type Props = {
  width?: string,
  placeholder: string,
  onInputChange: Function,
  options?: [{
    type: string,
    value: any,
    label: any,
  }],
  onSelect: Function,
}

const SearchWithOptions = ({
  width, placeholder, onInputChange, options, onSelect,
}: Props) => {
  const { isTouchDevice } = useWindowSize();
  const refWrapper = useRef(null);
  const refOptions = useRef(null);
  const [activeOpt, setActiveOpt] = useState(0);
  const [inputText, setInputText] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const handleClickOpt = useCallback((opt) => {
    setInputText(opt.label);
    if (onSelect) {
      onSelect(opt.value);
    }
  }, [onSelect]);
  const handleInputChange = useCallback((e) => {
    const { value } = e.target;
    setInputText(value);
    if (onInputChange) {
      onInputChange(value);
    }
  }, [onInputChange]);
  const handleFocus = useCallback(() => {
    setIsFocus(true);
    if (isTouchDevice && document?.body) {
      document.body.classList.add('no-scroll');
    }
    if (inputText && onInputChange) {
      onInputChange(inputText);
    }
  }, [isTouchDevice, inputText, onInputChange]);
  const handleBlur = useCallback(() => {
    setIsFocus(false);
    if (document.body) {
      document.body.classList.remove('no-scroll');
    }
    setActiveOpt(0);
    onInputChange('');
  }, [onInputChange]);
  const handleKeyEnter = useCallback((opts, activeOptIdx) => {
    const option = opts[activeOptIdx];
    if (option) {
      handleClickOpt(option);
    }
  }, [handleClickOpt]);
  const handleScrollToOpt = useCallback((idx) => {
    if (refOptions.current) {
      const nodeOpt = refOptions.current.querySelector(`.opt-${idx}`);
      scrollParentToChild(refOptions.current, nodeOpt);
    }
  }, []);
  const handleKeyArrowDown = useCallback((opts, activeOptIdx) => {
    let nextOptIdx = activeOptIdx + 1;
    while (true) {
      if (nextOptIdx > opts.length - 1) {
        for (let lastValidValueIdx = opts.length - 1; lastValidValueIdx >= 0; lastValidValueIdx -= 1) {
          const lastValidValue = options[lastValidValueIdx];
          if (lastValidValue.type === 'value') {
            setActiveOpt(lastValidValueIdx);
            return;
          }
        }
        return;
      }
      const nextOpt = opts[nextOptIdx];
      if (nextOpt.type === 'value') {
        setActiveOpt(nextOptIdx);
        handleScrollToOpt(nextOptIdx);
        return;
      }
      nextOptIdx += 1;
    }
  }, [handleScrollToOpt, options]);
  const handleKeyArrowUp = useCallback((opts, activeOptIdx) => {
    let prevOptIdx = activeOptIdx - 1;
    while (true) {
      if (prevOptIdx < 0) {
        for (let lastValidValueIdx = 0; lastValidValueIdx <= opts.length - 1; lastValidValueIdx += 1) {
          const lastValidValue = options[lastValidValueIdx];
          if (lastValidValue.type === 'value') {
            setActiveOpt(lastValidValueIdx);
            return;
          }
        }
        return;
      }
      const nextOpt = opts[prevOptIdx];
      if (nextOpt.type === 'value') {
        setActiveOpt(prevOptIdx);
        handleScrollToOpt(prevOptIdx);
        return;
      }
      prevOptIdx -= 1;
    }
  }, [handleScrollToOpt, options]);

  const handleMovingOpt = useCallback((e) => {
    switch (e.code) {
      case 'ArrowDown':
        e.preventDefault();
        handleKeyArrowDown(options, activeOpt);
        break;
      case 'ArrowUp':
        e.preventDefault();
        handleKeyArrowUp(options, activeOpt);
        break;
      case 'Enter':
        handleKeyEnter(options, activeOpt);
        break;
      default:
        break;
    }
  }, [handleKeyEnter, handleKeyArrowUp, handleKeyArrowDown, options, activeOpt]);
  useClickOutside(refWrapper, handleBlur, !isFocus);
  useEffect(() => {
    const getFirstIdx = options.findIndex((opt) => opt.type === 'value');
    setActiveOpt(getFirstIdx);
  }, [options]);
  useEffect(() => () => {
    if (document.body) {
      document.body.classList.remove('no-scroll');
    }
  }, []);
  const styleWrapper = { maxWidth: width };
  return (
    <div
      role="button"
      tabIndex={0}
      className={`cursor-auto relative w-full inline-block border-t border-r border-l ${isTouchDevice ? 'px-0' : 'px-4'} ${isFocus ? 'border-light-grey' : 'border-transparent'}`}
      ref={refWrapper}
      style={styleWrapper}
      onKeyDown={handleMovingOpt}
    >
      <div className="inline-flex flex-row items-center w-full border-b border-grey pl-2">
        <div className="inline-flex items-center justify-center text-base text-grey">
          {iconSearch('search')}
        </div>
        <input
          className="input border-0 outline-0 text-base text-black placeholder:text-grey"
          value={inputText}
          placeholder={placeholder}
          onChange={handleInputChange}
          onFocus={handleFocus}
        />
      </div>
      {isFocus && !isTouchDevice && (
        <div className=" absolute top-full -left-px list-none py-2 w-[calc(100%+2px)] z-50 bg-white border-l border-r border-b border-light-grey">
          <div className="flex-1 max-h-[500px] overflow-y-scroll w-full" ref={refOptions}>
            <ListOptions
              activeOpt={activeOpt}
              onClickOpt={handleClickOpt}
              options={options}
            />
          </div>
        </div>
      )}
      {isFocus && isTouchDevice && (
        <div className="fixed top-0 left-0 w-full h-full z-50 bg-white flex flex-col">
          <div className="flex flex-row border-b border-light-grey">
            <button
              className="w-12 text-base text-primary flex flex-col items-center justify-center"
              type="button"
              onClick={handleBlur}
            >
              {iconArrowLeft}
            </button>
            <input
              className="input border-none outline-none text-base h-12"
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus
              value={inputText}
              placeholder={placeholder}
              onChange={handleInputChange}
            />
          </div>
          <div className="overflow-y-scroll w-full flex-1">
            <ListOptions
              activeOpt={activeOpt}
              onClickOpt={handleClickOpt}
              options={options}
            />
          </div>
        </div>
      )}
    </div>
  );
};
SearchWithOptions.defaultProps = {
  width: '300px',
  options: [],
};
export default memo(SearchWithOptions);
