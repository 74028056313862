import React, { memo } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image-es5';

import Logo from '../../../ui/Logo';
import getCurrentDateAgendaURL from '../../../../helpers/getCurrentDateAgendaURL';

const Hero = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "hero-img.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );
  const imageData = data.desktop.childImageSharp.fluid;
  return (
    <section>
      <BackgroundImage
        className="bg-center bg-cover bg-no-repeat bg-primary"
        fluid={imageData}
      >
        <div
          className="flex text-white flex-col items-center min-h-[543px] max-h-[630px] h-screen md:h-[630px]
           md:min-h-0 md:max-h-full md:items-start w-full px-16 py-10 relative sm:bg-black/10 bg-black/25 "
        >
          <Logo className="h-[48px] md:h-[70px]" />
          <div className="absolute mx-auto top-[50%] sm:top-1/2 left-0 right-0 flex flex-col items-center
           -translate-y-1/2 max-w-[850px] w-full"
          >
            <p className="mt-6 text-[18px]">News et informations</p>
            <h1 className="h1 mt-3 leading-[1.25] font-medium text-[32px] md:text-[48px]  w-full text-center px-6">
              L’Agenda des Évènements Jazz
              {' '}
              <br />
              en Provence-Alpes-Côte d’Azur
            </h1>
            <div className="mt-16 sm:mt-9">
              <Link
                className="btn btn-transparent"
                to={getCurrentDateAgendaURL()}
              >
                Voir l&apos;agenda
              </Link>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </section>
  );
};

export default memo(Hero);
