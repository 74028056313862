import React, { memo } from 'react';
import { Link } from 'gatsby';

import Anchor from '../../ui/Anchor';
import getCurrentDateAgendaURL from '../../../helpers/getCurrentDateAgendaURL';

type Props = {
  citiesAlphabetically: {
    [key: string]: [{
      slug: string,
      name: string,
      lieu: [{
        slug: string,
        name: string,
        nbEvents: number,
      }]
    }]
  },
}

const PlacesAlphabetically = ({ citiesAlphabetically }: Props) => {
  const keysCities = Object.keys(citiesAlphabetically);
  return (
    <div className="mt-6">
      {keysCities.length > 0 ? keysCities.map((letter) => {
        const citiesByLetter = citiesAlphabetically[letter];
        return (
          <div key={letter} className="flex flex-row mb-6 last:mb-0">
            <div className="w-20">
              <Anchor offsetXl={56} offsetDesktop={56} offsetPhone={56} offsetTablet={56} anchorId={letter} />
              <h2 className="text-4xl font-medium text-primary sticky top-14 z-10">{letter}</h2>
            </div>
            <div className="flex-1">
              {citiesByLetter.map((city) => (
                <div key={city.slug} className="flex flex-col mb-6 last:mb-0">
                  <div>
                    <Link to={`${getCurrentDateAgendaURL()}?city=${city.slug}`} className="hover:underline">
                      <h3 className="text-primary text-base font-medium capitalize inline">{city.name.toLowerCase()}</h3>
                    </Link>
                  </div>
                  <div className="flex flex-col">
                    {city.lieu.map((place) => (
                      <div className="mt-2" key={place.slug}>
                        <Link className="hover:underline text-sm capitalize" to={`/place/${place.slug}/`}>
                          <span>{place.name.toLowerCase()}</span>
                          {place.nbEvents > 0 && <span className="font-semibold">{` (${place.nbEvents})`}</span>}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      }) : <div className="text-base text-purple">Pas de lieux trouvés</div>}
    </div>
  );
};

export default memo(PlacesAlphabetically);
