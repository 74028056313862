import React from 'react';

export const iconArrowLeft = (
  <svg width="1em" height="1em" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons/chevron-left" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon
        id="Path-2"
        fill="currentColor"
        fillRule="nonzero"
        points="5.67966776 7.5 11.25 12.8272673 11.25 12.8272673 10.2851661 13.75 10.2851661 13.75 3.75 7.5 10.2851661 1.25 11.25 2.17273274"
      />
    </g>
  </svg>
);

export const iconArrowRight = (
  <svg width="1em" height="1em" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons/chevron-right" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M3.75,2.17273274 C3.75,2.17273274 4.07161129,1.86515516 4.71483388,1.25 L11.25,7.5 L4.71483388,13.75 L3.75,12.8272673 L9.32033224,7.5 C5.60677741,3.94848849 3.75,2.17273274 3.75,2.17273274 Z"
        id="Path-2"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export const iconArrowDown = (
  <svg width="1em" height="1em" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons/chevron-down" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon
        id="chevron-down"
        fill="currentColor"
        fillRule="nonzero"
        points="7.5 9.32033224 12.8272673 3.75 12.8272673 3.75 13.75 4.71483388 13.75 4.71483388 7.5 11.25 1.25 4.71483388 2.17273274 3.75"
      />
    </g>
  </svg>
);

export const iconArrowUp = (
  <svg width="1em" height="1em" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons/chevron-up" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon id="Path-2" fill="currentColor" fillRule="nonzero" points="7.5 5.67966776 2.17273274 11.25 2.17273274 11.25 1.25 10.2851661 1.25 10.2851661 7.5 3.75 13.75 10.2851661 12.8272673 11.25" />
    </g>
  </svg>
);

export const iconSearch = (uniqId) => (
  <svg width="1em" height="1em" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.226 12.226C12.4087 12.0434 12.5 11.8272 12.5 11.5773C12.5 11.3223 12.4108 11.1056 12.2325 10.9273L9.7591 8.45391C10.3553 7.59412 10.6533 6.63526 10.6533 5.57733C10.6516 4.89156 10.5181 4.23423 10.2527 3.60533C9.9874 2.97642 9.62686 2.43539 9.17112 1.98224C8.71538 1.52908 8.17478 1.16854 7.54932 0.900611C6.92387 0.632681 6.26653 0.499147 5.57733 0.500008C4.88812 0.50087 4.23035 0.634404 3.60403 0.900611C2.97772 1.16682 2.43712 1.52736 1.98224 1.98224C1.52736 2.43712 1.16682 2.97815 0.900611 3.60533C0.634404 4.23251 0.50087 4.88984 0.500008 5.57733C0.499147 6.26481 0.632681 6.92214 0.900611 7.54932C1.16854 8.17651 1.52908 8.71753 1.98224 9.17241C2.43539 9.62729 2.97599 9.98783 3.60403 10.254C4.23208 10.5202 4.88984 10.6533 5.57733 10.6533C6.6344 10.6533 7.59326 10.3553 8.45391 9.7591L10.9273 12.226C11.1005 12.4087 11.3167 12.5 11.576 12.5C11.8267 12.5 12.0434 12.4087 12.226 12.226ZM7.85947 3.29518C8.49182 3.92667 8.80799 4.68738 8.80799 5.57733C8.80455 6.46727 8.48837 7.22798 7.85947 7.85947C7.23057 8.49096 6.46985 8.80713 5.57733 8.80799C4.6848 8.80886 3.92365 8.49268 3.29389 7.85947C2.66412 7.22626 2.34838 6.46554 2.34666 5.57733C2.34493 4.68911 2.66068 3.92839 3.29389 3.29518C3.9271 2.66197 4.68825 2.3458 5.57733 2.34666C6.4664 2.34752 7.22712 2.66369 7.85947 3.29518Z" fill="black" />
    <mask id={`mask0_0_5318${uniqId}`} style={{ markType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="13" height="13">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.226 12.226C12.4087 12.0434 12.5 11.8272 12.5 11.5773C12.5 11.3223 12.4108 11.1056 12.2325 10.9273L9.7591 8.45391C10.3553 7.59412 10.6533 6.63526 10.6533 5.57733C10.6516 4.89156 10.5181 4.23423 10.2527 3.60533C9.9874 2.97642 9.62686 2.43539 9.17112 1.98224C8.71538 1.52908 8.17478 1.16854 7.54932 0.900611C6.92387 0.632681 6.26653 0.499147 5.57733 0.500008C4.88812 0.50087 4.23035 0.634404 3.60403 0.900611C2.97772 1.16682 2.43712 1.52736 1.98224 1.98224C1.52736 2.43712 1.16682 2.97815 0.900611 3.60533C0.634404 4.23251 0.50087 4.88984 0.500008 5.57733C0.499147 6.26481 0.632681 6.92214 0.900611 7.54932C1.16854 8.17651 1.52908 8.71753 1.98224 9.17241C2.43539 9.62729 2.97599 9.98783 3.60403 10.254C4.23208 10.5202 4.88984 10.6533 5.57733 10.6533C6.6344 10.6533 7.59326 10.3553 8.45391 9.7591L10.9273 12.226C11.1005 12.4087 11.3167 12.5 11.576 12.5C11.8267 12.5 12.0434 12.4087 12.226 12.226ZM7.85947 3.29518C8.49182 3.92667 8.80799 4.68738 8.80799 5.57733C8.80455 6.46727 8.48837 7.22798 7.85947 7.85947C7.23057 8.49096 6.46985 8.80713 5.57733 8.80799C4.6848 8.80886 3.92365 8.49268 3.29389 7.85947C2.66412 7.22626 2.34838 6.46554 2.34666 5.57733C2.34493 4.68911 2.66068 3.92839 3.29389 3.29518C3.9271 2.66197 4.68825 2.3458 5.57733 2.34666C6.4664 2.34752 7.22712 2.66369 7.85947 3.29518Z" fill="white" />
    </mask>
    <g mask={`url(#mask0_0_5318${uniqId})`}>
      <rect x="-1" y="-1" width="15" height="15" fill="currentColor" />
    </g>
  </svg>
);

export const iconFacebook = (
  <svg width="1em" height="1em" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      {/* eslint-disable-next-line max-len */}
      <path d="M0,8.055 L0,5.19 L2.3099739,5.19 L2.3099739,3.75 C2.3099739,2.71 2.64747009,1.825 3.32246246,1.095 C3.99745483,0.365 4.81494559,0 5.77493475,0 L8.06990881,0 L8.06990881,2.865 L5.77493475,2.865 C5.62493644,2.865 5.48993797,2.9475 5.36993932,3.1125 C5.24994068,3.2775 5.18994136,3.48 5.18994136,3.72 L5.18994136,5.19 L8.06990881,5.19 L8.06990881,8.055 L5.18994136,8.055 L5.18994136,15 L2.3099739,15 L2.3099739,8.055 L0,8.055 Z" id="fb-path-1" />
    </defs>
    <g id="fb-Icons/facebook" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="fb-zocial-facebook-white" transform="translate(3.000000, 0.000000)">
        <mask id="fb-mask-2" fill="white">
          <use xlinkHref="#fb-path-1" />
        </mask>
        <use id="fb-Path" fill="#FFFFFF" fillRule="nonzero" xlinkHref="#fb-path-1" />
        <g id="fb-Group" mask="url(#fb-mask-2)">
          <g transform="translate(-3.000000, 0.000000)" id="fb-Colours/Black">
            <rect id="Rectangle" fill="currentColor" x="0" y="0" width="15" height="15" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const iconTwitter = (
  <svg width="1em" height="1em" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      {/* eslint-disable-next-line max-len */}
      <path d="M0,10.8170732 C0.243902439,10.8414634 0.487804878,10.8536585 0.731707317,10.8536585 C2.16260163,10.8536585 3.43902439,10.4146341 4.56097561,9.53658537 C3.89430894,9.52845528 3.29674797,9.32520325 2.76829268,8.92682927 C2.2398374,8.52845528 1.87804878,8.0203252 1.68292683,7.40243902 C1.84552846,7.43495935 2.03658537,7.45121951 2.25609756,7.45121951 C2.54065041,7.45121951 2.81300813,7.41463415 3.07317073,7.34146341 C2.35772358,7.20325203 1.76626016,6.85162602 1.29878049,6.28658537 C0.831300813,5.72154472 0.597560976,5.06910569 0.597560976,4.32926829 L0.597560976,4.29268293 C1.02845528,4.53658537 1.49593496,4.66260163 2,4.67073171 C1.57723577,4.39430894 1.24186992,4.0304878 0.993902439,3.57926829 C0.745934959,3.12804878 0.62195122,2.63821138 0.62195122,2.1097561 C0.62195122,1.55691057 0.764227642,1.04065041 1.04878049,0.56097561 C1.81300813,1.51219512 2.74593496,2.2703252 3.84756098,2.83536585 C4.94918699,3.4004065 6.1300813,3.71544715 7.3902439,3.7804878 C7.33333333,3.51219512 7.30487805,3.27642276 7.30487805,3.07317073 C7.30487805,2.22764228 7.60569106,1.50406504 8.20731707,0.902439024 C8.80894309,0.300813008 9.53658537,0 10.3902439,0 C11.2764228,0 12.0243902,0.325203252 12.6341463,0.975609756 C13.3414634,0.829268293 13.9918699,0.577235772 14.5853659,0.219512195 C14.3577236,0.959349593 13.9105691,1.52845528 13.2439024,1.92682927 C13.8373984,1.85365854 14.4227642,1.69105691 15,1.43902439 C14.6097561,2.04878049 14.101626,2.58130081 13.4756098,3.03658537 L13.4756098,3.43902439 C13.4756098,4.2601626 13.3556911,5.08333333 13.1158537,5.90853659 C12.8760163,6.73373984 12.5101626,7.52235772 12.0182927,8.27439024 C11.5264228,9.02642276 10.9390244,9.69512195 10.2560976,10.2804878 C9.57317073,10.8658537 8.75406504,11.3313008 7.79878049,11.6768293 C6.84349593,12.0223577 5.81707317,12.195122 4.7195122,12.195122 C3.0203252,12.195122 1.44715447,11.7357724 0,10.8170732 Z" id="tw-path-1" />
    </defs>
    <g id="tw-Icons/twitter" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="tw-zocial-twitter-gold" transform="translate(0.000000, 1.000000)">
        <mask id="tw-mask-2" fill="white">
          <use xlinkHref="#tw-path-1" />
        </mask>
        <use id="tw-Path" fill="#FFFFFF" fillRule="nonzero" xlinkHref="#tw-path-1" />
        <g id="tw-Group" mask="url(#tw-mask-2)">
          <g transform="translate(0.000000, -1.000000)" id="tw-Colours/Black">
            <rect id="tw-Rectangle" fill="currentColor" x="0" y="0" width="15" height="15" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const iconBurger = (
  <svg width="1em" height="0.6875em" viewBox="0 0 16 11" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <g id="Navbar/White/Mobile/white-navbar-mobile" transform="translate(-288.000000, -15.000000)" fill="currentColor">
        <g id="Group" transform="translate(288.000000, 15.000000)">
          <rect id="Rectangle" x="0" y="0" width="16" height="1.5" />
          <rect id="Rectangle-Copy" x="0" y="4.5" width="16" height="1.5" />
          <rect id="Rectangle-Copy-2" x="0" y="9" width="16" height="1.5" />
        </g>
      </g>
    </g>
  </svg>
);

export const iconClose = (
  <svg width="1em" height="1em" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      {/* eslint-disable-next-line max-len */}
      <path d="M14.4023509,5.05151476e-14 L15.5337218,1.13137085 L8.898,7.767 L15.5337218,14.4023509 L14.4023509,15.5337218 L7.767,8.898 L1.13137085,15.5337218 L2.13162821e-14,14.4023509 L6.636,7.767 L2.29816166e-13,1.13137085 L1.13137085,-8.18234369e-14 L7.767,6.636 L14.4023509,5.05151476e-14 Z" id="ic-path-1" />
    </defs>
    <g id="ic-Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="ic-Burger---Mobile" transform="translate(-284.000000, -20.000000)">
        <g id="ic-Group" transform="translate(280.000000, 16.000000)">
          <g id="ic-icon-close" transform="translate(4.240000, 4.233139)">
            <mask id="ic-mask-2" fill="white">
              <use xlinkHref="#ic-path-1" />
            </mask>
            <use id="ic-Combined-Shape" fill="currentColor" xlinkHref="#ic-path-1" />
            <g id="ic-Group" mask="url(#ic-mask-2)">
              <g transform="translate(-4.017139, -4.233139)" id="ic-Colour/black">
                <rect id="ic-Rectangle" fill="currentColor" x="0" y="0" width="24" height="24" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const iconSuccess = (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.34312 2.34312C3.84348 0.842824 5.87827 0 8 0C9.40433 0 10.7838 0.369502 12 1.07158C13.2161 1.77376 14.2261 2.78373 14.9283 3.99983C15.6304 5.21613 16 6.59574 16 8C16 10.1216 15.1572 12.1566 13.6569 13.6569C12.1565 15.1572 10.1216 16 8 16C5.87837 16 3.84341 15.1572 2.34312 13.6569C0.842823 12.1565 0 10.1216 0 8C0 5.87837 0.842823 3.84341 2.34312 2.34312ZM12.2936 5.41159C12.0222 5.14844 11.5848 5.1484 11.3134 5.41162L7.46427 9.14415L5.54365 7.28172C5.27228 7.01848 4.83486 7.01852 4.56347 7.28169C4.28837 7.54844 4.28832 7.98377 4.56347 8.2505L6.9742 10.5882C7.24557 10.8515 7.68298 10.8514 7.95438 10.5882L12.2936 6.38044C12.5687 6.11367 12.5688 5.67832 12.2936 5.41159Z"
      fill="currentColor"
    />
  </svg>
);
