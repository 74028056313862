import { css } from 'styled-components';

export const mediaSizes = {
  xl: 1440,
  desktop: 1080,
  tablet: 768,
  phone: 576,
  xsPhone: 374,
};
export const breakpoints = {
  '2xl': 1536,
  xl: 1280,
  lg: 1024,
  md: 768,
  sm: 640,
};

const media = Object.keys(mediaSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${mediaSizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export default media;
