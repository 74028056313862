import React, { memo } from 'react';
import { Link } from 'gatsby';

type Props = {
  url: string,
}

const NextMonth = ({ url }: Props) => (
  <div className="block mx-auto w-full text-right relative
    px-6 pt-6 pb-[72px] sm:px-12 sm:pb-10 lg:px-16 lg:pb-28
  "
  >
    <Link
      className="font-display text-primary link inline-flex flex-row items-center justify-center normal-case
        hover:after:block after:h-[5px] leading-normal
        text-[21px] sm:text-[32px] lg:text-[36px] xl:text-[42px] font-medium after:hidden"
      to={url}
    >
      <span>
        {'Mois '}
        <span className="italic">suivant</span>
      </span>
      <div className="ml-2 w-[32px] sm:ml-6 md:ml-8 sm:w-[76px] after:w-[30%] before:w-[30%]
       arrow-right sm:after:w-[18%] sm:before:w-[18%]"
      />
    </Link>
  </div>
);

export default memo(NextMonth);
