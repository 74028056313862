import React, { memo, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

import Breadcrumb from '../../elements/Breadcrumb';
import {
  generateGoogleMapsUrl, isBrowser, renderEventPrice, renderEventStatus,
} from '../../../helpers/utils';

import {
  ContainerHeader, Title, LinkPlace,
  LinkArtist, Artists, Infos, InfosElement, InfosTitle, InfosAddress, InfosDate, AButtonTicketing, Prices,
} from './EventHeader.styles';
import EventShare from './EventShare';

type Props = {
  event: {
    title: string,
    event_date: string,
    ticketing_url: string,
    has_ticketing_onsite: string,
    status: string,
    slug: string,
    place: {
      name: string,
      address: string,
      slug: string,
      city: {
        slug: string,
        name: string,
      }
    },
    entry_hours: {
      entryHours: [{
        startTime: string,
        endTime: string,
      }],
    },
    artists: [{
      name: string,
    }],
    prices: {
      prices: [{
        price: string,
        info: string,
      }],
    }
  }
};

const EventHeader = ({
  event,
}: Props) => {
  const {
    title, slug, artists, place, event_date: eventDate,
    entry_hours: dataEntryHours, ticketing_url: ticketingUrl, prices,
    status, has_ticketing_onsite: hasTicketingOnsite,
  } = event;
  const handleClickTicketingUrl = useCallback(() => {
    if (isBrowser && window.gtag) {
      window.gtag('event', 'click_ticketing_button', {
        music_event_slug: slug,
        ticketing_url: ticketingUrl,
      });
    }
  }, [ticketingUrl, slug]);
  const date = useMemo(() => dayjs(eventDate), [eventDate]);
  const entryHours = useMemo(() => {
    if (!(dataEntryHours?.entryHours?.length > 0)) return null;
    return dataEntryHours.entryHours.map((entryHour) => {
      const dStartTime = entryHour.startTime && dayjs(entryHour.startTime);
      const dEndTime = entryHour.endTime && dayjs(entryHour.endTime);
      return ({
        startTime: dStartTime && dStartTime.format('HH:mm'),
        endTime: dEndTime && dEndTime.format('HH:mm'),
      });
    });
  }, [dataEntryHours]);
  const eventTitle = useMemo(() => title.toLowerCase(), [title]);
  const breadcrumbLinks = useMemo(() => {
    const links = [];
    links.push({ label: date.format('MMM YYYY'), url: `/agenda/${date.format('YYYY/M')}/` });
    if (place) {
      if (place.city?.slug) {
        links.push({ label: place.city.name, url: `/agenda/${date.format('YYYY/M')}/?city=${place.city.slug}` });
      }
      links.push({ label: place.name, url: `/place/${place.slug}/` });
    }
    return links;
  }, [date, place]);
  const formattedPrices = useMemo(() => prices?.prices && prices.prices.reduce((acc, { price, info }) => {
    const formattedPrice = renderEventPrice(price, info);
    if (formattedPrice) {
      acc.push({ price, info, formattedPrice });
    }
    return acc;
  }, []), [prices]);
  const eventStatus = useMemo(() => renderEventStatus(status), [status]);
  const addressData = useMemo(() => {
    if (!place) return null;
    if (place.address) {
      return ({
        googleAddressUrl: generateGoogleMapsUrl(place.address),
        value: place.address,
      });
    }
    if (place.city?.name) {
      return ({
        value: place.city.name,
      });
    }
    return null;
  }, [place]);
  return (
    <ContainerHeader>
      <Breadcrumb links={breadcrumbLinks} />
      <Title>
        {eventStatus}
        {eventTitle}
      </Title>
      {artists?.length > 0 && (
        <Artists>
          {artists.map((artist, idx) => (
            <LinkArtist
              key={artist.slug}
              to={`/artist/${artist.slug}/`}
            >
              {`${artist.name.toLowerCase()}${idx < artists.length - 1 ? ',' : ''}`}
            </LinkArtist>
          ))}
        </Artists>
      )}
      <Infos>
        <InfosElement>
          <InfosTitle>Lieu</InfosTitle>
          <p>{place && <LinkPlace to={`/place/${place.slug}/`}>{place?.name}</LinkPlace>}</p>
          {addressData
            && (
            <InfosAddress
              href={addressData.googleAddressUrl}
              target="_blank"
              rel="noopener"
            >
              {addressData.value}
            </InfosAddress>
            )}
        </InfosElement>
        <InfosElement>
          <InfosTitle>Date</InfosTitle>
          <InfosDate>{date.format('dddd D MMMM YYYY')}</InfosDate>
          {entryHours
          && (
            <div>
              {entryHours.map(({ startTime, endTime }) => (
                <p key={startTime}>
                  {`${startTime}${endTime ? ` - ${endTime}` : ''}`}
                </p>
              ))}
            </div>
          )}
        </InfosElement>
        {formattedPrices?.length > 0
        && (
          <Prices>
            <InfosTitle>Tarifs</InfosTitle>
            {formattedPrices.map(({
              price,
              info,
              formattedPrice,
            }) => (
              <p key={`${price}${info}`}>{formattedPrice}</p>
            ))}
          </Prices>
        )}
        {(ticketingUrl || hasTicketingOnsite) && (
          <InfosElement>
            <InfosTitle>Billetterie</InfosTitle>
            {hasTicketingOnsite && <p>Guichet sur place</p>}
            {ticketingUrl && (
              <AButtonTicketing
                href={ticketingUrl}
                target="_blank"
                rel="noopener"
                onClick={handleClickTicketingUrl}
              >
                Acheter les billets
              </AButtonTicketing>
            )}
          </InfosElement>
        )}
        <EventShare event={event} />
      </Infos>
    </ContainerHeader>
  );
};

export default memo(EventHeader);
