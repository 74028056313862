import React from 'react';
import { css } from 'styled-components';
import { INLINES } from '@contentful/rich-text-types';

type NodeHyperlinkProps = {
  data: {
    uri: string,
  },
  content: [{
    value: string,
  }],
}
export const optionsRichTextRenderDefault = {
  renderNode: {
    [INLINES.HYPERLINK]: ({ data, content }: NodeHyperlinkProps) => (
      <a href={data.uri} target="_blank" rel="noopener noreferrer">
        {content[0].value}
      </a>
    ),
  },
};

const contentfulRichContentDefaultStyle = css`
  white-space: pre-line;
  font-size: 19px;
  line-height: 1.47;
  letter-spacing: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  a {
    text-decoration: underline;
    color: ${(p) => p.theme.blueJazz};
  }
  a:after {
    display: none;
  }
  p {
    font-size: 19px;
    line-height: 1.47;
    letter-spacing: normal;
    margin-bottom: 32px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  li p {
    margin: 0;
  }
  li:before {
    line-height: 0.7;
  }
  ${(p) => css`
    ${p.theme.media.phone`
      font-size: 17px;
      p {
        margin-bottom: 28px;
        font-size: 17px;
      }
    `}
  `}
  ${(p) => css`
    ${p.theme.media.phone`
      ul {
        padding-left: 24px;
      }
      p {
        margin-bottom: 28px;
      }
    `}
  `}
`;

export default contentfulRichContentDefaultStyle;
