import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import qs from 'qs';
import { navigate } from 'gatsby';

import { isBrowser } from '../../helpers/utils';

const Bg = styled.div`
  background: ${(p) => p.theme.lightGrey};
  height: 100vh;
`;
const Popup = styled.div`
  position: fixed;
  max-width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 32px;
  
  h1 {
    font-size: 28px;
  }
  p {
    margin-top: 16px;
    font-size: 16px;
  }
`;

const ContainerInputKey = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  input {
    flex: 1;
    font-size: 16px;
  }
  button {
    height: 42px;
  }
`;

const Success = styled.div`
  margin-top: 16px;
  color: green;
`;

type Props = {
  location: {
    hash: string,
    search: string,
  },
};
const Preview = ({ location }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [previewAccessToken, setPreviewAccessToken] = useState(false);
  const [previewKeyIsSetup, setPreviewKeyIsSetup] = useState(false);

  const handleChangeInput = useCallback((e) => setPreviewAccessToken(e.target.value), []);
  const handleValidKey = useCallback(async () => {
    localStorage.setItem('preview-access-token', previewAccessToken);
    const previewReturnUrl = localStorage.getItem('preview-return-url');
    if (previewReturnUrl) {
      localStorage.removeItem('preview-return-url');
      await navigate(previewReturnUrl);
      return true;
    }
    setPreviewKeyIsSetup(true);
    return true;
  }, [previewAccessToken]);

  useEffect(() => {
    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (isBrowser) {
      if (queryParams.return_url) {
        localStorage.setItem('preview-return-url', queryParams.return_url);
      }
    }
    setIsLoading(false);
    return true;
  }, [location]);
  return (
    <Bg>
      <Popup>
        {isLoading
          ? <div>Chargement...</div>
          : (
            <>
              <h1>Demande d&apos;authentification</h1>
              <p>
                {/* eslint-disable-next-line max-len */}
                Vous devez vous authentifier avec une clé Preview pour pouvoir accéder aux aperçus des entités (évènements, artistes...) en
                {' '}
                <b>Draft</b>
                {' '}
                (brouillon).
              </p>
              <ContainerInputKey>
                <input
                  onChange={handleChangeInput}
                  placeholder="Clé preview de forme xxx-xxxxxxxxxxxxxxxxxx--xxxxxxxxxxxxxxxxxxx"
                />
                <button
                  type="button"
                  onClick={handleValidKey}
                >
                  Valider
                </button>
              </ContainerInputKey>
              {previewKeyIsSetup && (
              <Success>
                La clé Preview a été installé correctement, vous pouvez retourner sur les pages d&apos;aperçu
              </Success>
              )}
            </>

          )}
      </Popup>
    </Bg>
  );
};

export default memo(Preview);
