export default function mapEntryToEvent(entry) {
  const { sys, fields } = entry;
  const event = {
    contentful_id: sys.id,
    artists: fields.artists ? fields.artists.map((artist) => artist.fields) : null,
    title: fields.title || '',
    status: fields.status,
    slug: fields.slug,
    ticketing_url: fields.ticketing_url,
    has_ticketing_onsite: fields.has_ticketing_onsite,
    event_date: fields.event_date,
    prices: fields.prices,
    entry_hours: fields.entry_hours,
    description: fields.description && { raw: JSON.stringify(fields.description) },
    infos: fields.infos && { raw: JSON.stringify(fields.infos) },
    place: {},
    youtubeVideos: fields.youtubeVideos,
  };
  if (fields.place) {
    const { fields: fieldsPlace } = fields.place;
    event.place = {
      address: fieldsPlace.address,
      geoAddress: fieldsPlace.geoAddress,
      name: fieldsPlace.name,
      slug: fieldsPlace.slug,
    };
    if (fieldsPlace.city) {
      event.place.city = fieldsPlace.city.fields;
    }
  }
  if (fields.thumbnail) {
    const { fields: fieldsThumbnail } = fields.thumbnail;
    event.thumbnail = {
      title: fieldsThumbnail.title,
      file: fieldsThumbnail.file,
    };
  }

  return event;
}
