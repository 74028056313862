import React, { memo, useMemo } from 'react';
import dayjs from 'dayjs';

import EventsFiltersDesktop from './EventsFiltersDesktop';
import EventsFiltersMobile from './EventsFiltersMobile';

type Props = {
  currentCity: string,
  currentMonth: string,
  currentYear: string,
  currentEventsView: string,
  onChangeEventView: Function,
  onSelectCity: Function,
  pathNextMonth: string,
  pathPreviousMonth: string
}

const EventsFilters = ({
  currentEventsView, currentMonth, currentYear, currentCity,
  pathNextMonth, pathPreviousMonth, onChangeEventView, onSelectCity,
}: Props) => {
  const pathToday = useMemo(() => {
    const now = dayjs();
    return `/agenda/${now.year()}/${now.month() + 1}/#today`;
  }, []);
  return (
    <>
      <EventsFiltersDesktop
        currentMonth={currentMonth}
        currentYear={currentYear}
        currentEventsView={currentEventsView}
        currentCity={currentCity}
        pathNextMonth={pathNextMonth}
        pathPreviousMonth={pathPreviousMonth}
        pathToday={pathToday}
        onChangeEventView={onChangeEventView}
        onSelectCity={onSelectCity}
      />
      <EventsFiltersMobile
        currentMonth={currentMonth}
        currentYear={currentYear}
        currentEventsView={currentEventsView}
        currentCity={currentCity}
        pathNextMonth={pathNextMonth}
        pathPreviousMonth={pathPreviousMonth}
        pathToday={pathToday}
        onChangeEventView={onChangeEventView}
        onSelectCity={onSelectCity}
      />
    </>
  );
};

export default memo(EventsFilters);
