import React, { createContext, useReducer } from 'react';

export const CalendarContext = createContext();

type Action = {
  type: string,
  pageTranslateY: number,
  event: {},
}

const calendarReducer = (state, action: Action) => {
  switch (action.type) {
    case 'setHoveredEvent':
      return {
        ...state,
        hoveredEvent: action.event,
      };
    default:
      return state;
  }
};

const initialState = {
  hoveredEvent: null,
  timeoutBlur: null,
};

type Props = {
  children: any,
};
const CalendarProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(calendarReducer, initialState);
  const providerValue = {
    state,
    dispatch,
  };
  return (
    <CalendarContext.Provider value={providerValue}>
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarProvider;
