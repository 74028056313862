import React, {
  memo, useCallback, useRef, useState,
} from 'react';

import { iconSearch } from './Icons';

type Props = {
  idKey: string,
  width?: string,
  placeholder: string,
  onChange: Function,
}

const Search = ({
  idKey, width, placeholder, onChange,
}: Props) => {
  const refWrapper = useRef(null);
  const [inputText, setInputText] = useState('');
  const handleInputChange = useCallback((e) => {
    const { value } = e.target;
    setInputText(value);
    if (onChange) {
      onChange(value);
    }
  }, [onChange]);
  const handleFocus = useCallback(() => {
    if (inputText && onChange) {
      onChange(inputText);
    }
  }, [inputText, onChange]);
  const styleWrapper = { maxWidth: width };
  return (
    <div
      role="button"
      tabIndex={0}
      className="cursor-auto relative w-full inline-block"
      ref={refWrapper}
      style={styleWrapper}
    >
      <div className="inline-flex flex-row items-center w-full border-b border-grey pl-2">
        <div className="inline-flex items-center justify-center text-base text-grey">
          {iconSearch(idKey)}
        </div>
        <input
          className="input border-0 outline-0 text-base text-black placeholder:text-grey"
          value={inputText}
          placeholder={placeholder}
          onChange={handleInputChange}
          onFocus={handleFocus}
        />
      </div>
    </div>
  );
};
Search.defaultProps = {
  width: '300px',
};
export default memo(Search);
