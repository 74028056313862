import React, { memo } from 'react';

import Content from '../../../ui/Content';
import { FACEBOOK_GROUP } from '../../../../constants/general';

const WeGotNewsletter = () => (
  <section className="bg-primary text-white">
    <Content>
      <h2 className="h2">Pssst... Il y a du neuf!</h2>
      <p className="p mt-6 md:mt-8">
        Dès aujourd&apos;hui, soyez branché sur les prochains évènements Jazz en
        Provence-Alpes-Côte d&apos;Azur grace à notre newsletter!
      </p>
      <div className="mt-6 flex flex-row gap-4">
        <a className="btn btn-transparent" href="#newsletter">La newsletter</a>
        <a className="btn btn-transparent" href={FACEBOOK_GROUP} target="_blank" rel="noopener noreferrer">Le groupe facebook</a>
      </div>
    </Content>
  </section>
);

export default memo(WeGotNewsletter);
