import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import { navigate } from 'gatsby';
import dayjs from 'dayjs';

import Search from '../../ui/SearchWithOptions';
import { throttle } from '../../../helpers/utils';
import contentfulClient from '../../../helpers/contentfulClient';

const SearchArtistsOrEvents = () => {
  const [options, setOptions] = useState([]);

  const handleInputChange = useCallback(async (value) => {
    if (value?.length < 2) {
      setOptions([]);
      return null;
    }
    const [artists, events, places] = await Promise.all([contentfulClient.getEntries({
      content_type: 'artist',
      limit: 5,
      'fields.name[match]': value,
      select: 'sys.id,fields.name,fields.slug',
    }), contentfulClient.getEntries({
      content_type: 'event',
      limit: 5,
      'fields.title[match]': value,
      select: 'sys.id,fields.title,fields.slug,fields.event_date',
      order: '-fields.event_date',
    }), contentfulClient.getEntries({
      content_type: 'place',
      limit: 5,
      'fields.name[match]': value,
      select: 'sys.id,fields.name,fields.slug,fields.city',
    })]);
    const opts = [];
    if (events?.items?.length > 0) {
      opts.push({ type: 'divider', label: 'Évènements' });
      events.items.forEach((event) => {
        const { title, slug, event_date: eventDate } = event.fields;
        opts.push({
          type: 'value',
          label: `${dayjs(eventDate).format('DD MMM YYYY')} - ${title}`,
          value: `/event/${slug}/`,
        });
      });
    }
    if (artists?.items?.length > 0) {
      opts.push({ type: 'divider', label: 'Artistes' });
      artists.items.forEach((artist) => {
        const { name, slug } = artist.fields;
        opts.push({ type: 'value', label: name, value: `/artist/${slug}/` });
      });
    }
    if (places?.items?.length > 0) {
      opts.push({ type: 'divider', label: 'Lieux' });
      places.items.forEach((place) => {
        const { name, slug, city } = place.fields;
        const label = `${name}${city?.fields?.name ? ` - ${city.fields.name}` : ''}`;
        opts.push({ type: 'value', label, value: `/place/${slug}/` });
      });
    }
    if (opts.length === 0) {
      opts.push({ type: 'not_found', label: `Pas d'évènements, lieux ou/et d'artistes pour '${value}'` });
    }
    setOptions(opts);
    return true;
  }, []);
  const handleSelect = useCallback((value) => navigate(value), []);
  const throttledOnChange = useMemo(() => throttle(handleInputChange, 800), [handleInputChange]);
  return (
    <div className="text-center">
      <Search
        placeholder="Chercher un artiste, lieu ou évènement"
        width="450px"
        options={options}
        onInputChange={throttledOnChange}
        onSelect={handleSelect}
      />
    </div>
  );
};

export default memo(SearchArtistsOrEvents);
