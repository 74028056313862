import React, { memo, useMemo } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import dayjs from 'dayjs';

import { optionsRichTextRenderDefault } from '../../../styles/contentfulRichContent';
import Layout from '../../elements/Layout/Layout';
import generateEventSchema from '../../../helpers/generateEventSchema';

import EventHeader from './EventHeader';
import EventDescription from './EventDescription';
import EventInfos from './EventInfos';
import FutureEvents from './FutureEvents';
import EventIsPreview from './EventIsPreview';
import EventVideos from './EventVideos';

type Props = {
  isPreview: boolean,
  event: {
    id: string,
    contentful_id: string,
    title: string,
    description: string,
    infos: string,
    slug: string,
    event_date: string,
    youtubeVideos: [string] | null,
    thumbnail: {
      title: string,
      gatsbyImageData: {},
      file: {
        url: string,
      }
    },
    artists: [{
      name: string,
    }],
    place: {
      slug: string,
      name: string,
      city: {
        slug: string,
        name: string,
      }
    }
  },
  futureEventsSameCity: [],
  futureEventsSamePlace: [],
};

const EventPresentation = ({
  isPreview, event, futureEventsSameCity, futureEventsSamePlace,
}: Props) => {
  const {
    contentful_id: contentfulId, title, thumbnail, description, infos, place, event_date: eventDate, slug, artists,
    youtubeVideos,
  } = event;
  const renderedDescription = description && renderRichText({ raw: description.raw }, optionsRichTextRenderDefault);
  const renderedInfos = infos && renderRichText({ raw: infos.raw }, optionsRichTextRenderDefault);
  const seo = useMemo(() => {
    const allArtists = artists && artists.map((artist) => artist.name).join(', ');
    const metaDescription = allArtists && `Avec ${allArtists}${place?.city?.name ? ` à ${place.city.name}` : ''}.`;
    return ({
      title: `${isPreview ? '[PREVIEW] ' : ''}${title} - Dom'Jazz`,
      description: metaDescription,
      image: thumbnail?.file?.url && `https:${thumbnail.file.url}`,
      externalImg: true,
      url: `/event/${slug}/`,
      pageSchema: generateEventSchema(event, metaDescription),
    });
  }, [artists, place, isPreview, title, thumbnail, slug, event]);
  const date = useMemo(() => dayjs(eventDate), [eventDate]);

  return (
    <Layout seo={seo} showNavbar light>
      {slug
        ? (
          <>
            {isPreview && <EventIsPreview eventContentfulId={contentfulId} />}
            <EventHeader event={event} />
            <EventDescription description={renderedDescription} thumbnail={thumbnail} />
            {youtubeVideos && <EventVideos youtubeVideos={youtubeVideos} />}
            {renderedInfos && <EventInfos infos={renderedInfos} />}
            {place && futureEventsSamePlace?.length > 0 && (
              <FutureEvents
                events={futureEventsSamePlace}
                title={`Prochainement à ${place.name}`}
                urlMore={`/place/${place.slug}/`}
              />
            )}
            {place?.city && futureEventsSameCity?.length > 0 && (
              <FutureEvents
                events={futureEventsSameCity}
                title={`Prochainement à ${place.city.name}`}
                urlMore={`/agenda/${date.format('YYYY/M')}/?city=${place.city.slug}`}
              />
            )}
          </>
        ) : <div>L&apos;évènement n&apos;existe pas ou plus.</div>}

    </Layout>
  );
};

export default memo(EventPresentation);
