import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import ContentfulPicture from '../../ui/ContentfulPicture';
import contentfulRichContentDefaultStyle from '../../../styles/contentfulRichContent';

const Background = styled.div`
  background: ${(p) => p.theme.darkBlue};
`;
const ContainerDescription = styled.div`
  padding: 64px;
  display: flex;
  flex-direction: row;
  max-width: 1920px;
  width: 100%;
  margin: auto;
  ${(p) => css`
    ${p.theme.media.desktop`
      flex-direction: column-reverse;
    `}
    ${p.theme.media.tablet`
      padding: 48px;    
    `}
    ${p.theme.media.phone`
      padding: 0 24px 40px 24px;
    `}
  `}
`;
const Description = styled.div`
  ${contentfulRichContentDefaultStyle}
  
  flex: 1;
  color: white;
  padding-right: 56px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  p {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 32px;
  }
  a {
    color: white;
  }
  ${(p) => css`
    ${p.theme.media.desktop`
      margin-top: 48px;
      padding-right: 0;
    `}
    ${p.theme.media.tablet`
      margin-top: 32px;
      padding-right: 0;
    `}
    ${p.theme.media.phone`
      p {
        margin-bottom: 28px;
        font-size: 17px;
      }
    `}
  `}
`;
const Flyer = styled.div`
  flex: 0.8;

  img {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    z-index: 3;
    width: 100%;
    object-fit: contain;
    object-position: right;
    max-height: calc(100vh - 64px);
  }
  ${(p) => css`
    ${p.theme.media.desktop`
      position: static;
      img {    
        object-position: center;
      }
    `}
    ${p.theme.media.tablet`
      img {
        object-position: center center;
      }
    `}
    ${p.theme.media.phone`
      margin: 0 -24px;
    `}
  `}
`;

type Props = {
  thumbnail: {
    title: string,
    file: {
      url: string,
    },
    gatsbyImageData: {},
  },
  description: string,
}

const EventDescription = ({ description, thumbnail }: Props) => (
  <Background>
    <ContainerDescription>
      <Description lang="fr">
        {description}
      </Description>
      <Flyer>
        {thumbnail && (
          <ContentfulPicture
            alt={thumbnail.title}
            url={`https:${thumbnail.file.url}`}
            contentfulGatsbyImage={thumbnail.gatsbyImageData}
          />
        )}
      </Flyer>
    </ContainerDescription>
  </Background>
);

export default memo(EventDescription);
