import React, {
  memo, useEffect, useMemo, useRef,
} from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import clamp from 'clamp-js';
import { Link } from 'gatsby';

import ContentfulPicture from '../../ui/ContentfulPicture';
import { prettyPrice, renderEventStatus } from '../../../helpers/utils';

type Props = {
  className?: string,
  event: {
    id: string,
    title: string,
    slug: string,
    status: string,
    artists: [{
      id: string,
      name: string,
    }],
    place: {
      name: string,
      city?: {
        name: string,
      }
    },
    prices: {
      id: string,
      prices: [{
        info: string,
        price: string,
      }],
    },
    thumbnail: {
      title: string,
      gatsbyImageData: {}
    }
  }
}

const EventListElement = ({
  className,
  event: {
    title, thumbnail, slug, artists, place, prices, status,
  },
}: Props) => {
  const refTitle = useRef(null);
  const refArtists = useRef(null);
  const allArtists = useMemo(() => artists && artists.map((artist) => artist.name).join(', ').toLowerCase(),
    [artists]);
  const eventStatus = useMemo(() => renderEventStatus(status), [status]);
  useEffect(() => {
    clamp(refTitle.current, { clamp: 2 });
    if (artists) {
      clamp(refArtists.current, { clamp: 1 });
    }
  }, [artists]);
  const priceInfo = useMemo(() => {
    if (!prices) return null;
    return prices.prices.reduce((info, priceData) => {
      if (!priceData?.price) return info;
      const priceNb = Number.parseFloat(priceData.price);
      if (info.min === null || priceNb < info.min) {
        info.min = priceNb;
      }
      if (info.max === null || priceNb > info.max) {
        info.max = priceNb;
      }
      return info;
    }, { min: null, max: null });
  }, [prices]);

  return (
    <Link className={`${className} block group`} to={`/event/${slug}/`}>
      <div className="flex flex-col sm:flex-row w-full">
        <div className="flex-1 sm:flex-none w-full sm:w-auto relative sm:pt-0 pt-[60.10928%]">
          {thumbnail ? (
            <ContentfulPicture
              className="absolute align-bottom left-0 top-0 w-full h-full object-top object-cover sm:static sm:w-[90px] sm:h-[123px] sm:object-center xl:w-[102px] xl:h-[139px]"
              alt={thumbnail.title}
              contentfulGatsbyImage={thumbnail.gatsbyImageData}
            />
          )
            : (
              <StaticImage
                imgClassName="object-center object-cover sm:w-[90px] sm:h-[123px] sm:object-center xl:w-[102px] xl:h-[139px]"
                className="absolute align-bottom left-0 top-0 w-full h-full  sm:static sm:w-[90px] sm:h-[123px]  xl:w-[102px] xl:h-[139px]"
                src="../../../assets/images/default_thumbnail.png"
                alt={`default image for ${title}`}
                placeholder="blurred"
                layout="constrained"
                width={204}
                height={278}
                quality={100}
              />
            )}
        </div>
        <div className="flex flex-col pt-2 sm:pt-0 pl-0 sm:pl-4">
          <h2
            className="leading-normal font-medium text-[17px] sm:text-[19px] md:text-[21px] xl:text-[24px] text-primary capitalize group-hover:underline"
            ref={refTitle}
            title={title}
          >
            {eventStatus}
            {title.toLowerCase()}
          </h2>
          <p
            className="text-[14px] sm:text-sm md:text-base xl:text-[19px] leading-normal mt-0.5 sm:mt-2 capitalize text-black"
            ref={refArtists}
            title={allArtists}
          >
            {allArtists}
          </p>
          <div className="flex-1 flex flex-col text-[13px] leading-normal text-grey mt-1.5 sm:mt-2
          sm:text-sm sm:flex-row sm:flex-wrap
          md:text-base xl:text-[19px]"
          >
            {place
            && (
              <p className="sm:pr-5 md:pr-10 leading-normal">
                {`${place.name} / ${place.city?.name || ''}`}
              </p>
            )}
            {priceInfo?.min !== null
            && (
              <p className="text-primary leading-normal">
                {/* eslint-disable-next-line max-len */}
                {`${prettyPrice(priceInfo.min)}${priceInfo.min !== priceInfo.max ? `-${prettyPrice(priceInfo.max)}` : ''}`}
              </p>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};
EventListElement.defaultProps = {
  className: undefined,
};
export default memo(EventListElement);
