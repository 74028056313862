import React, { memo } from 'react';
import { graphql } from 'gatsby';

import EventPresentation from './EventPresentation';

type Props = {
  data: {
    contentfulEvenement: {
      title: string,
      description: string,
      infos: string,
      slug: string,
      event_date: string,
      thumbnail: {
        title: string,
        gatsbyImageData: {},
        file: {
          url: string,
        }
      },
      artists: [{
        name: string,
      }],
      place: {
        city: {
          name: string,
        }
      }
    },
    futureEventsSameCity: {
      nodes: [],
    },
    futureEventsSamePlace: {
      nodes: [],
    },
  }
};

const Event = ({ data }: Props) => (
  <EventPresentation
    event={data.contentfulEvenement}
    futureEventsSameCity={data.futureEventsSameCity.nodes}
    futureEventsSamePlace={data.futureEventsSamePlace.nodes}
  />
);

export const query = graphql`
  query Event($slug: String!, $citySlug: String, $placeSlug: String, $eventDate: Date) {
    contentfulEvenement(slug: {
      eq: $slug
    }) {
      ...EventFull
    }
    futureEventsSamePlace: allContentfulEvenement(filter: {
      slug: { ne: $slug }
      place: { slug: { eq: $placeSlug } }
      status: { nin: ["Annulé", "Reporté"] }
      event_date: { gte: $eventDate }
    }, sort: { order: ASC, fields: event_date }, limit: 4) {
      nodes {
        ...EventInfos
      }
    }
    futureEventsSameCity: allContentfulEvenement(filter: {
      slug: { ne: $slug }
      place: { city: { slug: { eq: $citySlug } }}
      status: { nin: ["Annulé", "Reporté"] }
      event_date: { gte: $eventDate }
    }, sort: { order: ASC, fields: event_date }, limit: 4) {
      nodes {
        ...EventInfos
      }
    }
  }
`;

export default memo(Event);
