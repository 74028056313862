import React, { memo, useCallback } from 'react';

import Search from '../../ui/Search';

type Props = {
  searchId: string,
  onChange: (value: string) => void,
  nbTotalEvents: number,
  isHidingEmptyPlaces: boolean,
  onChangeHidingEmptyPlaces: (newStatus: boolean) => void,
}

const PlacesSearch = ({
  onChange, nbTotalEvents, searchId, isHidingEmptyPlaces, onChangeHidingEmptyPlaces,
}: Props) => {
  const handleChangeHidingEmptyPlaces = useCallback((e) => {
    onChangeHidingEmptyPlaces(e.target.checked);
  }, [onChangeHidingEmptyPlaces]);
  return (
    <div>
      <Search
        idKey={searchId}
        placeholder="Chercher par lieu ou ville"
        onChange={onChange}
      />
      <div className="text-primary text-base mt-4">
        <span className="font-semibold">{nbTotalEvents}</span>
        {' évènements à venir'}
      </div>
      <label htmlFor="hide-empty-places" className="block mt-4 items-center flex-wrap">
        <input type="checkbox" id="hide-empty-places" checked={isHidingEmptyPlaces} onChange={handleChangeHidingEmptyPlaces} className="checkbox h-4 w-4 align-middle" />
        <span className="ml-2 text-sm align-middle">Cacher villes et lieux sans évènements</span>
      </label>
    </div>
  );
};

export default memo(PlacesSearch);
