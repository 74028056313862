/* eslint-disable */

import "./src/assets/fonts/NeueMachina/stylesheet.css"
import "./src/assets/fonts/Telegraf/stylesheet.css"

import React from 'react';
import { ThemeProvider } from 'styled-components';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import isToday from 'dayjs/plugin/isToday';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import 'dayjs/locale/fr';

import './src/styles/global.css';
import theme from './src/styles/theme';

dayjs.locale('fr')
dayjs.extend(weekday);
dayjs.extend(isToday);
dayjs.extend(isSameOrBefore);

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
);


export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import("intersection-observer")
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}
