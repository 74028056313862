import React, { memo } from 'react';
import { Link } from 'gatsby';

type Props = {
  letters: string[],
}
const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
const PlacesNavigation = ({ letters }: Props) => (
  <div className="flex flex-row overflow-x-scroll items-center justify-start text-center py-4 bg-white text-base sticky bg-white top-0 z-20
  -mr-6 sm:-mr-12 md:mr-0 no-scrollbar"
  >
    {alphabet.map((letter) => {
      if (letters.indexOf(letter) > -1) {
        return (
          <Link
            key={letter}
            className="text-primary uppercase mx-1.5 first:ml-0 last:mr-0 hover:underline"
            to={`#${letter}`}
          >
            {letter}
          </Link>
        );
      }
      return <p key={letter} className="uppercase text-grey-2 mx-1.5 first:ml-0 last:mr-0">{letter}</p>;
    })}
  </div>
);

export default memo(PlacesNavigation);
