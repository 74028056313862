import React, { memo } from 'react';
import cs from 'classnames';
import { Link } from 'gatsby';

import getCurrentDateAgendaURL from '../../../helpers/getCurrentDateAgendaURL';
import Logo from '../../ui/Logo';
import { iconClose } from '../../ui/Icons';

type Props = {
  open: boolean,
  onClose: Function,
}

const MobileMenu = ({ onClose, open }: Props) => {
  const links = [
    { url: getCurrentDateAgendaURL(), label: 'L\'Agenda' },
    { url: '/', label: 'L\'Association' },
    { url: '/places', label: 'Les Lieux' },
  ];
  return (
    <div className={cs(
      'fixed h-full w-full top-0 -right-full bg-white md:hidden z-[100] flex-col transition-transform duration-400', {
        '-translate-x-full': open,
      },
    )}
    >
      <button type="button" className="absolute z-30 text-lg top-8 right-6 sm:right-12" onClick={onClose}>
        {iconClose}
      </button>
      <div className="px-8 py-6">
        <div className="flex flex-row items-center justify-center text-center">
          <Link to="/">
            <Logo className="h-[48px] text-primary w-auto" />
          </Link>
        </div>
        <div className="mt-24">
          {links.map((link) => (
            <Link
              key={link.url}
              className="block mb-6 font-display text-2xl sm:text-3xl text-center font-normal last:mb-0"
              to={link.url}
            >
              {link.label}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(MobileMenu);
