import { graphql } from 'gatsby';

import Home from './Home';

export default Home;

export const query = graphql`
  query NextEvents($currentDate: Date!) {
    nextEvents: allContentfulEvenement(filter: {
      event_date: { gte: $currentDate }
      status: { nin: ["Annulé", "Reporté"] }
    }, sort: { order: ASC, fields: event_date }, limit: 4) {
      edges {
        node {
          ...EventInfos
        }
      }
    }
  }
`;
