import React, { memo } from 'react';
import { Link } from 'gatsby';

import MonthSelector from './MonthSelector';
import CityFilter from './CityFilter';

type Props = {
  currentCity: string,
  currentMonth: string,
  currentYear: string,
  onSelectCity: Function,
  pathNextMonth: string,
  pathPreviousMonth: string,
  pathToday: string,
}

const EventsFiltersMobile = ({
  currentMonth, currentYear, currentCity,
  pathNextMonth, pathPreviousMonth, onSelectCity, pathToday,
}: Props) => (
  <div className="flex flex-col bg-white border-b border-light-grey px-4 pt-1 pb-4 lg:hidden
    sm:py-4 sm:px-12 md:py-6 lg:px-16
  "
  >
    <div className="flex flex-col items-center">
      <MonthSelector
        currentYear={currentYear}
        currentMonth={currentMonth}
        pathNextMonth={pathNextMonth}
        pathPreviousMonth={pathPreviousMonth}
      />
    </div>
    <div className="flex flex-row flex-1 gap-1 justify-between items-center mt-2">
      <div className="flex-1 min-w-0">
        <CityFilter className="max-w-[200px]" label="Ville:" currentCity={currentCity} onSelectCity={onSelectCity} />
      </div>
      <div>
        <Link className="btn btn-secondary p-2 sm:px-4 " to={pathToday}>Aujourd&apos;hui</Link>
      </div>
    </div>
  </div>
);

export default memo(EventsFiltersMobile);
