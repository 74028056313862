import React, {
  memo, useMemo,
} from 'react';

type Props = {
  place: {
    name: string,
    address: string,
  }
}

const PlaceHeader = ({
  place: {
    name,
    address,
  },
}: Props) => {
  // eslint-disable-next-line max-len
  const srcEmbedMap = useMemo(() => `https://www.google.com/maps/embed/v1/place?key=${process.env.GATSBY_GMAPS_EMBED_API}&q=${encodeURIComponent(address)}`,
    [address]);

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="flex-1 sm:pr-6">
        <h1 className="text-primary text-[32px] sm:text-[40px] md:text-[56px] font-display capitalize mt-4 sm:mt-0 leading-[normal]">{name.toLowerCase()}</h1>
        <p className="text-[18px] sm:text-[22px] mt-3 leading-[1.47] text-grey">{address}</p>
      </div>
      <div className="w-full max-w-[590px] mt-6 lg:mt-0">
        <div className="h-0 relative overflow-hidden pb-[56.25%]">
          <iframe
            className="left-0 top-0 h-full w-full absolute"
            title={`Google Map of ${name}`}
            width="600"
            height="450"
            frameBorder="0"
            referrerPolicy="no-referrer-when-downgrade"
            src={srcEmbedMap}
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
};
export default memo(PlaceHeader);
