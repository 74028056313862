import React, { memo, useMemo } from 'react';
import { graphql } from 'gatsby';
import dayjs from 'dayjs';

import Layout from '../../elements/Layout';
import EventsTiles from '../../elements/EventsTiles';

import ArtistHeader from './ArtistHeader';

type Props = {
  data: {
    contentfulArtiste: {
      name: string,
      slug: string,
    },
    evenements: {
      nodes: []
    }
  }
}
const Artist = ({ data }: Props) => {
  const { contentfulArtiste, evenements } = data;
  const seo = useMemo(() => ({
    title: `${contentfulArtiste.name} - Dom'Jazz`,
    description: `Découvrez tous les dates des évènements Jazz de ${contentfulArtiste.name}\
en région Provence-Alpes-Côte d'Azur et bien plus. Une sélection proposée par Dom'Jazz.`,
    url: `/artist/${contentfulArtiste.slug}/`,
  }), [contentfulArtiste]);
  const events = useMemo(() => {
    const dToday = dayjs();
    return (evenements?.nodes || []).reduce((acc, event) => {
      if (dToday.isSameOrBefore(event.event_date)) {
        acc.future.push(event);
      } else {
        acc.past.push(event);
      }
      return acc;
    }, { future: [], past: [] });
  }, [evenements]);
  return (
    <Layout seo={seo} showNavbar light>
      <div className="max-w-[1920px] w-full mx-auto
      px-6 pt-6 pb-12
      sm:px-12 sm:pt-8 sm:pb-20
      md:px-16 md:pt-20 md:pb-40
      "
      >
        <ArtistHeader artist={contentfulArtiste} />
        <div className="mt-20">
          <h2 className="text-primary text-[30px] sm:text-[38px] md:text-[48px] mb-6 sm:mb-8 md:mb-14 font-display">
            Évènements à venir
          </h2>
          {events.future.length > 0 ? <EventsTiles events={events.future} />
            : <div className="text-purple text-2xl">Pas d&apos;évènements à venir</div>}
        </div>
        <div className="mt-20">
          <h2 className="text-primary font-display text-[30px] sm:text-[38px] md:text-[48px] mb-6 sm:mb-8 md:mb-14">
            Évènements passés
          </h2>
          {events.past.length > 0 ? <EventsTiles events={events.past} />
            : <div className="text-purple text-2xl">Pas d&apos;évènements passés</div>}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Artist($slug: String!) {
    contentfulArtiste(slug: {
      eq: $slug
    }) {
      id
      name
      role
      slug
      biography {
        raw
      }
      profile_picture {
        id
        title
        gatsbyImageData(
          height: 632, quality: 100)
      }
    }
    evenements: allContentfulEvenement(filter: {
      artists: {
        elemMatch: {
          slug: { eq: $slug }
        }
      }
    }, sort: { order: DESC, fields: event_date }) {
      nodes {
        ...EventInfos
      }
    }
  }
`;

export default memo(Artist);
