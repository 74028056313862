import media from '../helpers/media';

export default {
  // palette
  black: '#1d1d1d',
  blueJazz: '#1c00ff',
  darkBlue: '#2512c5',
  grey: '#7d7d7d',
  lightGrey: '#e9e9e9',
  white: '#ffffff',
  midBlue: '#4933ff',
  purple: '#7766ff',
  lightPurple: '#d2ccff',

  // font family
  fontFamilySans: 'Telegraf, Arial, serif',
  fontFamilyDisplay: '"Neue Machina", Arial, serif',

  // media queries
  media,
};
