import React, { memo } from 'react';
import dayjs from 'dayjs';

import Day from './Day';
import FloatingEventFlyer from './FloatingEventFlyer';
import CalendarProvider from './CalendarProvider';

type Props = {
  events: [{
    date: {},
    dateFormatted: string,
    dayEvents: [],
    isToday: boolean,
    isCurrentMonth: boolean,
  }],
  month: number,
}

const weekdays = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
const CalendarEvents = ({ events, month }: Props) => {
  const today = dayjs();
  const isTodayMonth = today.month() + 1 === month;
  const todayDayIdx = today.day() - 1;
  return (
    <CalendarProvider>
      <div className="pt-6 px-2">
        <div className="sticky top-[85px] bg-white py-2 z-20 grid grid-cols-7 gap-4">
          {weekdays.map((day, index) => (
            <div
              className={`text-[19px] ${isTodayMonth && todayDayIdx === index ? 'text-primary' : ''}`}
              key={day}
            >
              {day}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-7 gap-4">
          {events.map(({
            date, dateFormatted, events: dayEvents, isToday, isCurrentMonth,
          }) => (
            <Day
              key={dateFormatted}
              date={date}
              dateFormatted={dateFormatted}
              events={dayEvents}
              isToday={isToday}
              disabled={!isCurrentMonth}
            />
          ))}
        </div>
      </div>
      <FloatingEventFlyer />
    </CalendarProvider>
  );
};

export default memo(CalendarEvents);
