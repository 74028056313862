import React, { memo } from 'react';

import EventTileElement from '../EventTileElement';

type Props = {
  className?: string,
  events: [],
  elementWidth: string
}

const EventsTiles = ({ className, events, elementWidth }: Props) => (
  <div className={className}>
    {events.map((event) => (
      <EventTileElement key={event.slug} event={event} width={elementWidth} />
    ))}
  </div>
);

EventsTiles.defaultProps = {
  className: 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-9',
};
export default memo(EventsTiles);
