import React, { memo, useMemo } from 'react';
import dayjs from 'dayjs';

import Anchor from '../../ui/Anchor';

import EventRow from './EventRow';

type Props = {
  dateFormatted: string,
  date: dayjs,
  events: [],
  isToday: boolean,
  disabled: boolean,
}

const Day = ({
  date, dateFormatted, events, disabled, isToday,
}: Props) => {
  const colorClassContainer = useMemo(() => {
    if (disabled) return 'text-light-grey border-light-grey';
    if (isToday) return 'text-primary border-primary';
    return 'text-light-purple border-light-purple';
  }, [disabled, isToday]);
  return (
    <div className="min-h-[150px] mb-6 pb-8 flex flex-col">
      <div className={`${colorClassContainer} sticky z-10 top-[129px] bg-white border-t-4`}>
        <h3 className={`text-[46px] don leading-tight ${isToday ? 'mb-1' : 'mb-2'}`}>
          <time className="leading-tight" dateTime={dateFormatted}>{date.date()}</time>
        </h3>
        {isToday && (
          <>
            <Anchor anchorId="today" offsetXl={250} />
            <p className="text-xs font-bold leading-4 mb-1 uppercase tracking-wider">
              Aujourd&apos;hui
            </p>
          </>
        )}
      </div>
      {!disabled
        && (
          <>
            {events.map((event, idx) => (
              <EventRow isTodayFirstEvent={isToday && idx === 0} key={event.id} event={event} />
            ))}
          </>
        )}
    </div>
  );
};

export default memo(Day);
