import React, {
  memo, useCallback, useContext, useEffect, useMemo, useRef,
} from 'react';
import clamp from 'clamp-js';
import { Link } from 'gatsby';

import { renderEventStatus } from '../../../helpers/utils';

import { CalendarContext } from './CalendarProvider';

type Props = {
  isTodayFirstEvent: boolean,
  event: {
    id: string,
    title: string,
    slug: string,
    status: string,
    artists: [{
      id: string,
      name: string,
    }],
    place: {
      name: string,
      city?: {
        name: string,
      }
    },
  }
}

const EventRow = ({ event, isTodayFirstEvent }: Props) => {
  const { dispatch } = useContext(CalendarContext);
  const refTitle = useRef(null);
  const refArtists = useRef(null);
  const artists = useMemo(() => event.artists && event.artists.map((artist) => artist.name).join(', ').toLowerCase(),
    [event.artists]);
  const eventStatus = useMemo(() => renderEventStatus(event.status), [event.status]);
  const handleHoverEvent = useCallback(() => {
    dispatch({ type: 'setHoveredEvent', event });
  }, [dispatch, event]);
  const handleHoverOutEvent = useCallback(() => {
    dispatch({ type: 'setHoveredEvent', event: null });
  }, [dispatch]);
  useEffect(() => {
    clamp(refTitle.current, { clamp: 2 });
    if (artists) {
      clamp(refArtists.current, { clamp: 2 });
    }
  }, [artists]);
  return (
    <Link
      to={`/event/${event.slug}/`}
      className={`${isTodayFirstEvent ? 'mt-0' : 'mt-4'} group`}
      nospace={isTodayFirstEvent.toString()}
      onMouseOver={handleHoverEvent}
      onMouseOut={handleHoverOutEvent}
    >
      <div className="normal-case">
        <h4
          className="group-hover:underline text-sm xl:text-base font-display font-bold text-primary leading-[1.47]"
          title={event.title}
          ref={refTitle}
        >
          {eventStatus}
          {event.title}
        </h4>
        {artists && (
        <p
          className="text-black text-xs xl:text-[13px] leading-[1.47] mt-1.5 capitalize"
          title={artists}
          ref={refArtists}
        >
          {artists}
        </p>
        )}
        {event.place
        && (
          <p className="text-grey text-xs xl:text-[13px] leading-[1.47] mt-1.5">
            {`${event.place.name} / ${event.place.city?.name || ''}`}
          </p>
        )}
      </div>
    </Link>
  );
};

export default memo(EventRow);
