import React, { memo } from 'react';

type Props = {
  error: {
    message: string
  }
}

const FormError = ({ error }: Props) => {
  if (!error) return null;
  return (
    <div className="mt-1 text-error text-sm">{error.message || 'Erreur dans le champs'}</div>
  );
};

export default memo(FormError);
