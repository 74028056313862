import React, { memo, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import ButtonDownloadEventICSFile from '../../elements/ButtonDownloadEventICSFile';
import { iconFacebook, iconTwitter } from '../../ui/Icons';

import { InfosElement, InfosTitle } from './EventHeader.styles';

type Props = {
  event: {
    slug: string,
  }
}

const EventShare = ({ event }: Props) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const socialMedias = useMemo(() => {
    const sharingUrl = encodeURIComponent(`${site.siteMetadata.siteUrl}/event/${event.slug}`);
    const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${sharingUrl}`;
    const twUrl = `https://twitter.com/intent/tweet?url=${sharingUrl}`;

    return [{ key: 'fb', url: fbUrl, icon: iconFacebook }, { key: 'twitter', url: twUrl, icon: iconTwitter }];
  }, [event.slug, site.siteMetadata.siteUrl]);
  return (
    <InfosElement>
      <InfosTitle>Partager</InfosTitle>
      <div className="mt-4">
        {socialMedias.map((media) => (
          <a
            className="text-[20px] mr-4 last:mr-0 hover:text-purple text-primary transition-colors inline-block"
            key={media.key}
            href={media.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {media.icon}
          </a>
        ))}
      </div>
      <ButtonDownloadEventICSFile event={event} />
    </InfosElement>
  );
};

export default memo(EventShare);
