import React, { memo } from 'react';
import { Link } from 'gatsby';
import dayjs from 'dayjs';

import Content from '../../ui/Content';
import Copyright from '../Copyright/Copyright';
import Logo from '../../ui/Logo';
import EnveloppeSvg from '../../../assets/images/svg/envelope.inline.svg';
import { CONTACT_EMAIL, FACEBOOK_GROUP, GFORM_URL } from '../../../constants/general';
import { iconFacebook } from '../../ui/Icons';

const Footer = () => {
  const todayDate = new Date();
  const currentYear = todayDate.getFullYear();
  const currentMonth = todayDate.getMonth() + 1;
  return (
    <footer>
      <Content className="border-t border-light-grey md:py-16">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          <div>
            <Logo className="text-primary" size={70} />
            <div className="text-xs mt-4 leading-normal">
              Association Dom’Jazz
              <br />
              Présidente : Dominique Kuentz
              <br />
              327, rue du Général Brosset
              <br />
              83600 FREJUS
            </div>
            <div className="mt-6">
              <h6 className="h6">Contact</h6>
              <div className="flex flew-row items-center mt-1 md:mt-4 text-xs">
                <span className="mr-3 text-lg text-primary">
                  <EnveloppeSvg />
                </span>
                <span>{CONTACT_EMAIL}</span>
              </div>
              {/* <div className="flex flew-row items-center mt-3 text-xs"> */}
              {/*   <span className="mr-3 text-lg text-primary"> */}
              {/*     <PhoneSvg /> */}
              {/*   </span> */}
              {/*   <span>06 16 08 22 51</span> */}
              {/* </div> */}
            </div>
          </div>
          <div>
            <h6 className="h6">
              L&apos;Agenda
              {' '}
              {currentYear}
              {' '}
              par mois
            </h6>
            <div className="mt-1 md:mt-4">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
                <Link key={month} to={`/agenda/${currentYear}/${month}`} className="block capitalize mb-1 last:mb-0 hover:underline text-primary">
                  <span>{dayjs(`${currentYear}-${month}-1`).format('MMMM')}</span>
                  {currentMonth === month && <span className="text-black">{' - actuel'}</span>}
                </Link>
              ))}
            </div>
          </div>
          <div>
            <h6 className="h6">Découvrir</h6>
            <div className="mt-1 md:mt-4">
              <Link to="/places" className="hover:underline text-primary">
                Les lieux
              </Link>
            </div>
          </div>
          <div>
            <h6 className="h6">Contribuer</h6>
            <a className="flex flex-row mt-1 md:mt-4 items-center hover:underline text-primary" href={FACEBOOK_GROUP} target="_blank" rel="noopener noreferrer">
              <span className="mr-3">{iconFacebook}</span>
              Notre groupe facebook
            </a>
            <div className="flex flex-col items-start leading-normal bg-white mt-4 ">
              <div>
                Vous êtes un professionnel et souhaitez apparaître sur l&apos;agenda?
              </div>
              <a className="btn mt-4" href={GFORM_URL} target="_blank" rel="noopener noreferrer">
                Partagez mes évènements
              </a>
            </div>
          </div>
        </div>
      </Content>
      <Copyright />
    </footer>
  );
};

export default memo(Footer);
