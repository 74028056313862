import React, {
  memo,
} from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { StaticImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

import { optionsRichTextRenderDefault } from '../../../styles/contentfulRichContent';
import ContentfulPicture from '../../ui/ContentfulPicture';
import ContentClamp from '../../elements/ContentClamp';

const Biography = styled(ContentClamp)`
  .content {
    max-width: 1200px;
    margin-top: 24px;
    color: ${(p) => p.theme.grey};
    font-size: 19px;
    line-height: 1.47;
    p {
      font-size: 19px;
      line-height: 1.47;
      margin-bottom: 22px;
    }
    p:last-child {
      margin-bottom: 0;
    }
    ${(p) => css`
      ${p.theme.media.tablet`
        font-size: 17px;      
        margin-top: 18px;
        p {
          font-size: 17px;      
        }
      `}
      ${p.theme.media.phone`
        margin-top: 18px;
        font-size: 13px;
        line-height: 1.69;
        p {
          font-size: 13px;
          line-height: 1.69;
          margin-bottom: 22px;
        }
      `}
  `}
  }
`;

type Props = {
  artist: {
    name: string,
    role: string,
    biography: {
      raw: string,
    },
    profile_picture: {
      title: string,
      gatsbyImageData: {},
    },
  }
}

const ArtistHeader = ({
  artist: {
    name, role, biography, profile_picture: profilePicture,
  },
}: Props) => {
  const renderedBiography = biography && renderRichText({ raw: biography.raw }, optionsRichTextRenderDefault);
  return (
    <div className="flex flex-col-reverse text-center sm:text-left sm:flex-col">
      <div className="flex-1">
        <div className="
          sm:float-right sm:pl-6 sm:pb-6
          xl:pl-12 xl:pb-0
       ">
          {profilePicture
            ? (
              <ContentfulPicture
                className="inline-block object-cover rounded-full object-center aspect-square bg-primary w-[84px] sm:w-[140px] md:w-[250px] xl:w-[316px]"
                alt={profilePicture.title}
                contentfulGatsbyImage={profilePicture.gatsbyImageData}
              />
            )
            : (
              <StaticImage
                className="rounded-full aspect-square bg-primary w-[84px] sm:w-[140px] md:w-[250px] xl:w-[316px]"
                imgClassName="object-cover rounded-full object-center aspect-square bg-primary w-[84px] sm:w-[140px] md:w-[250px] xl:w-[316px]"
                src="../../../assets/images/default_thumbnail.png"
                alt={`default image for ${name}`}
                placeholder="blurred"
                layout="constrained"
                height={632}
                quality={100}
              />
            )}
        </div>
        <h1 className="text-primary font-display text-[32px] sm:text-[40px] md:text-[56px] font-medium capitalize mt-4 sm:mt-0 leading-[normal]">{name.toLowerCase()}</h1>
        {role && (
        <div className="mt-2 sm:mt-4 md:mt-6 leading-[1.65] sm:leading-[1.46] capitalize text-[17px] sm:text-2xl text-black">
          {role}
        </div>
        )}
        {biography && (
          <Biography nbLines={3} maxHeight={105}>
            {renderedBiography}
          </Biography>
        )}
      </div>
    </div>
  );
};

export default memo(ArtistHeader);
