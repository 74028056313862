import { CONTACT_EMAIL } from '../constants/general';

export default function generateGlobalSchema(siteUrl) {
  return [
    {
      '@context': 'https://schema.org/',
      '@type': 'Organization',
      legalName: 'Dom\'Jazz',
      url: siteUrl,
      contactPoint: {
        '@type': 'ContactPoint',
        email: CONTACT_EMAIL,
        contactType: 'customer support',
      },
      email: CONTACT_EMAIL,
      logo: `${siteUrl}/logo-domjazz-blue.svg`,
      address: {
        '@type': 'PostalAddress',
        streetAddress: '327 rue du général brosset',
        addressLocality: 'FREJUS',
        addressRegion: 'Provence-Alpes-Côte d\'Azur',
        postalCode: '83600',
        addressCountry: 'FR',
      },
    },
    {
      '@context': 'https://schema.org/',
      '@type': 'WebSite',
      name: 'Dom\'Jazz',
      url: siteUrl,
    },
  ];
}
