import React, { memo } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Content from '../../../ui/Content';
import Quote from '../../../ui/Quote';

const Association = () => (
  <>
    <section>
      <Content>
        <div className="flex flex-col-reverse gap-8 items-center lg:flex-row lg:items-center lg:gap-20">
          <div className="lg:w-[40%]">
            <StaticImage
              src="../../../../assets/images/asso_1.png"
              alt="A jazzman"
              objectFit="contain"
              className="h-[400px] lg:h-auto"
            />
          </div>
          <div className="flex-1">
            <h2 className="h2 text-primary">L&apos;Association</h2>
            <p className="p mt-6 md:mt-8">
              <span className="text-primary">Dom’Jazz</span>
              {' '}
              est une association, Loi 1901, dédiée à la promotion et au partage du Jazz.
              Elle est située à Fréjus, ville d’Art et d’Histoire, dans le Département du Var.
            </p>
            <p className="p mt-6 md:mt-8">
              L’association
              {' '}
              <span className="text-primary">Dom’Jazz</span>
              {' '}
              est née d’un groupe d’amis passionnés de jazz et désireux de se retrouver pour partager,
              faire aimer et découvrir cette musique centenaire, empreinte de liberté et d’improvisation.
            </p>
            <div className="mt-6 md:mt-8">
              <Quote author="Michel Legrand">
                « Il y a des lieux, des expériences, des rencontres où notre vie prend soudain quelques centimètres de plus,
                et alors la Vie prend de la hauteur !»
              </Quote>
            </div>
          </div>
        </div>
      </Content>
    </section>
    <section className="bg-light-grey-2">
      <Content>
        <div className="flex flex-col gap-8 items-center lg:flex-row lg:items-center lg:gap-20">
          <div className="flex-1">
            <h3 className="h2 text-primary">L&apos;Agenda</h3>
            <p className="p mt-6 md:mt-8">
              <span className="text-primary">Dom’Jazz</span>
              , L’Agenda Jazz en Provence-Alpes-Côte d’Azur, annonce et promeut les Evénements Jazz de la Région Sud.
            </p>
            <p className="p mt-6 md:mt-8">
              Véritable lien d’information entre le public, les organisateurs d’événements Jazz et les artistes,
              {' '}
              <span className="text-primary">Dom’Jazz</span>
              {' '}
              apporte une visibilité accrue des programmations de la
              Région
              Provence-Alpes-Côte d&apos;Azur grâce à son actualisation quotidienne et la plus exhaustive possible.
              De plus, il permet au public de découvrir de façon détaillée et fonctionnelle les artistes et les lieux ainsi que de réserver ou acheter ses places.
            </p>
            <p className="p mt-6 md:mt-8">
              <span className="text-primary">Dom’Jazz</span>
              {' '}
              c’est aussi :
            </p>
            <p className="p mt-6 md:mt-8">
              La diffusion d’informations sur l’actualité du Jazz : Newsletters, Coups de coeur sur
              des projets musicaux, sorties d’albums, Masterclass, etc…
            </p>
          </div>
          <div className="lg:w-[40%]">
            <StaticImage
              src="../../../../assets/images/asso_2.png"
              alt="A jazzman"
              objectFit="contain"
              className="h-[400px] lg:h-auto"
            />
          </div>
        </div>
      </Content>
    </section>
  </>

);

export default memo(Association);
