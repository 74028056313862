import React, { memo } from 'react';

import ButtonMultiToggle from '../../ui/ButtonMultiToggle';

import MonthSelector from './MonthSelector';
import CityFilter from './CityFilter';

type Props = {
  currentCity: string,
  currentMonth: string,
  currentYear: string,
  currentEventsView: string,
  onChangeEventView: Function,
  onSelectCity: Function,
  pathNextMonth: string,
  pathPreviousMonth: string,
  pathToday: string,
}

const viewOpts = [
  {
    value: 'list',
    label: 'Liste',
  }, {
    value: 'calendar',
    label: 'Calendrier',
  },
];

const EventsFiltersDesktop = ({
  currentEventsView, currentMonth, currentYear, currentCity,
  pathNextMonth, pathPreviousMonth, onChangeEventView, onSelectCity, pathToday,
}: Props) => (
  <div className="hidden lg:block border-b border-light-grey bg-white">
    <div className="py-6 px-16 flex flex-row items-center bg-white max-w-[1920px] w-full mx-auto">
      <MonthSelector
        currentYear={currentYear}
        currentMonth={currentMonth}
        pathNextMonth={pathNextMonth}
        pathPreviousMonth={pathPreviousMonth}
        pathToday={pathToday}
      />
      <div className="flex-1 inline-flex flex-row justify-end text-right items-center px-6">
        <div className="w-[200px]">
          <CityFilter currentCity={currentCity} onSelectCity={onSelectCity} />
        </div>
      </div>
      <div className="inline-flex flex-row justify-end">
        {currentEventsView
        && (
          <ButtonMultiToggle
            label="Afficher par:"
            options={viewOpts}
            defaultValue={currentEventsView}
            onSelect={onChangeEventView}
          />
        )}
      </div>
    </div>
  </div>
);

export default memo(EventsFiltersDesktop);
