import React, {
  memo, useEffect, useMemo, useRef,
} from 'react';
import dayjs from 'dayjs';
import clamp from 'clamp-js';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import ContentfulPicture from '../../ui/ContentfulPicture';
import { renderEventStatus } from '../../../helpers/utils';

type Props = {
  width?: string,
  event: {
    status: string,
    title: string,
    slug: string,
    thumbnail: {
      title: string,
      gatsbyImageData: {},
    },
    event_date: string,
    artists: [{
      name: string,
    }]
  },
}

const EventTileElement = ({
  event: {
    title, slug, thumbnail, event_date: eventDate, artists, status,
  },
}: Props) => {
  const refTitle = useRef(null);
  const refArtists = useRef(null);
  const date = useMemo(() => dayjs(eventDate), [eventDate]);
  const eventStatus = useMemo(() => renderEventStatus(status), [status]);
  const allArtists = useMemo(() => artists && artists.map((artist) => artist.name).join(', ').toLowerCase(),
    [artists]);
  useEffect(() => {
    clamp(refTitle.current, { clamp: 3 });
    if (artists) {
      clamp(refArtists.current, { clamp: 3 });
    }
  }, [artists]);
  return (
    <Link
      className="inline-block group w-full"
      to={`/event/${slug}/`}
    >
      <div className="w-full h-[400px] overflow-hidden hover:rounded-[150px] cubic1">
        {thumbnail
          ? (
            <ContentfulPicture
              className="w-full h-full object-cover object-center"
              alt={thumbnail.title}
              contentfulGatsbyImage={thumbnail.gatsbyImageData}
            />
          )
          : (
            <StaticImage
              className="w-full h-full"
              src="../../../assets/images/default_thumbnail.png"
              alt={`default image for ${title}`}
              placeholder="blurred"
              layout="constrained"
              height={1000}
              quality={100}
            />
          )}
      </div>
      <p className="mt-2 sm:mt-4 text-[14px] leading-[1.68] capitalize text-black">
        <time dateTime={date.format('YYYY-MM-DD')}>{date.format('ddd DD MMM')}</time>
      </p>
      <h4
        className="font-display text-[21px] leading-[1.3] mt-1 sm:mt-2 sm:text-2xl font-medium sm:leading-normal inline-block capitalize text-primary group-hover:underline"
        title={title}
        ref={refTitle}
      >
        {eventStatus}
        {title.toLowerCase()}
      </h4>
      <p className="text-[17px] sm:text-[19px] leading-[1.47] capitalize mt-1 sm:mt-2" title={allArtists} ref={refArtists}>{allArtists}</p>
    </Link>
  );
};

EventTileElement.defaultProps = {
  width: '25%',
};
export default memo(EventTileElement);
