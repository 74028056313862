import React, { memo, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import EventsTiles from '../../elements/EventsTiles';
import useWindowSize from '../../../hooks/useWindowSize';
import { mediaSizes } from '../../../helpers/media';

const Container = styled.div`
  padding: 64px;
  max-width: 1920px;
  width: 100%;
  margin: auto;
  ${(p) => css`
    ${p.theme.media.tablet`
      padding: 48px;
    `}
    ${p.theme.media.phone`
      padding: 40px 24px 48px;
    `}
  `}
`;
const Title = styled.h2`
  font-family: ${(p) => p.theme.fontFamilyDisplay};
  font-size: 48px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: ${(p) => p.theme.blueJazz};
  margin-bottom: 48px;
  ${(p) => css`
    ${p.theme.media.xl`
      font-size: 36px;
    `}
    ${p.theme.media.tablet`
      font-size: 38px;
      margin-bottom: 32px;
    `}
    ${p.theme.media.phone`
      font-size: 30px;
      margin-bottom: 32px;
    `}
  `}
`;

type Props = {
  events: [],
  title: string,
  urlMore: string,
}

const FutureEvents = ({
  events, title, urlMore,
}: Props) => {
  const windowSize = useWindowSize();
  const eventsLimited = useMemo(() => {
    if (events && windowSize.width <= mediaSizes.xl && windowSize.width > mediaSizes.desktop && events.length > 3) {
      return events.slice(0, 3);
    }
    return events;
  }, [events, windowSize.width]);
  return (
    <Container>
      <Title>{title}</Title>
      <EventsTiles events={eventsLimited} />
      <div className="mt-8 sm:mt-10 text-center">
        <Link
          className="btn btn-secondary inline-block w-full sm:w-auto"
          to={urlMore}
        >
          Découvrir plus d&apos;évènements
        </Link>
      </div>
    </Container>
  );
};

export default memo(FutureEvents);
