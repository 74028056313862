import React, {
  memo, useCallback, useMemo, useRef, useState,
} from 'react';

import useClickOutside from '../../hooks/useClickOutside';

import { iconArrowDown, iconArrowUp } from './Icons';

type Props = {
  className: string,
  label: string,
  options: [{
    value: string,
    label: string,
  }],
  defaultValue: string,
  onSelect: Function,
}

const Select = ({
  className, label, options, defaultValue, onSelect,
}: Props) => {
  const refSelect = useRef(null);
  const [value, setValue] = useState(defaultValue);
  const [showOpts, setShowOpts] = useState(false);
  const handleToggleShowOpts = useCallback(() => setShowOpts((old) => !old), []);
  const handleSelectValue = useCallback((selectedValue) => () => {
    setValue(selectedValue);
    setShowOpts(false);
    if (onSelect) {
      onSelect(selectedValue);
    }
  }, [onSelect]);
  const handleChangeNativeSelect = useCallback((event) => {
    handleSelectValue(event.target.value)();
  }, [handleSelectValue]);

  useClickOutside(refSelect, () => {
    setShowOpts(false);
  }, !showOpts);

  const activeOpt = useMemo(() => options.find((opt) => opt.value === value), [options, value]);
  return (
    <div className={`inline-flex w-full flex-row items-center bg-white relative ${className || ''}`}>
      <div className="inline-block sm:mr-2 text-base leading-normal text-left">{label}</div>
      <div
        className={`flex-1 min-w-0 px-2 sm:px-4 relative flex flex-col border-t border-l border-r ${showOpts ? 'border-light-grey' : 'border-transparent'}`}
        ref={refSelect}
      >
        <select className="absolute inset-0 opacity-0 text-base pointer-events-auto md:pointer-events-none" value={value} onChange={handleChangeNativeSelect}>
          {options.map((opt) => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
        </select>
        <button
          className="text-left min-w-0 pt-1 pb-1 w-full flex flex-1 flex-row items-center
          justify-between text-primary bg-white border-b border-primary leading-normal text-base"
          type="button"
          onClick={handleToggleShowOpts}
        >
          <span className="flex-1 min-w-0 pr-2 truncate">{activeOpt?.label || value}</span>
          <span>{showOpts ? iconArrowUp : iconArrowDown}</span>
        </button>
        {showOpts
          && (
            <ul className="absolute bg-white text-left top-full -left-px -right-px list-none px-2 sm:px-4 pb-2 border-l border-r
            border-b border-light-grey max-h-[50vh] overflow-scroll overscroll-contain"
            >
              {options.map((opt) => (
                <li key={opt.value} className="py-1.5">
                  <button
                    type="button"
                    className="text-primary text-left text-sm hover:text-purple transition-colors "
                    onClick={handleSelectValue(opt.value)}
                  >
                    {opt.label}
                  </button>
                </li>
              ))}
            </ul>
          )}
      </div>
    </div>
  );
};

export default memo(Select);
