import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import AWithButtonStyle from '../../ui/AWithButtonStyle';

export const ContainerHeader = styled.div`
  padding: 32px 64px 106px 64px;
  max-width: 1920px;
  width: 100%;
  margin: auto;
  ${(p) => css`
    ${p.theme.media.xl`
      padding: 32px 64px 88px 64px;
    `}    
    ${p.theme.media.tablet`
      padding: 32px 48px 80px 48px;
    `}    
    ${p.theme.media.phone`
       padding: 24px 24px 48px 24px;
    `}
  `}
`;

export const Title = styled.h1`
  max-width: 1440px;
  font-family: ${(p) => p.theme.fontFamilyDisplay};
  font-size: 56px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  text-transform: capitalize;
  color: ${(p) => p.theme.blueJazz};
  margin-top: 32px;
  ${(p) => css`
    ${p.theme.media.xl`
      max-width: 1080px;
      font-size: 48px;
    `}
    ${p.theme.media.tablet`
      font-size: 40px;
    `}
    ${p.theme.media.phone`
      font-size: 32px;
      margin-top: 16px;
    `}
  `}
`;

export const LinkArtist = styled(Link)`
  display: inline-block;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.46;
  text-transform: capitalize;
  letter-spacing: 0;
  color: ${(p) => p.theme.black};
  position: relative;
  ${(p) => css`
    ${p.theme.media.xl`
      font-size: 21px;
    `}
    ${p.theme.media.phone`
      font-size: 17px;
    `}
  `}
  &:hover {
    text-decoration: underline;
  }
  &:after {
    display: none;
  }
  &:not(:last-child) {
    margin-right: 12px;
  }
`;

export const LinkPlace = styled(Link)`
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0;
  color: inherit;
  position: relative;
  &:hover {
    text-decoration: underline;
  }
  &:after {
    display: none;
  }
`;

export const Artists = styled.div`
  margin-top: 24px;
  ${(p) => css`
    ${p.theme.media.phone`
      margin-top: 16px;
    `}
  `}
`;

export const Infos = styled.div`
  margin: 48px -64px -32px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${(p) => css`
    ${p.theme.media.xl`
      margin: 40px -32px -32px 0;
    `}
    ${p.theme.media.desktop`
      margin: 40px -32px -32px 0;
    `}
    ${p.theme.media.tablet`
      margin: 40px -32px -32px 0;
    `}
    ${p.theme.media.phone`
      margin: 32px -16px -24px 0;
    `}
  `}
`;
export const InfosElement = styled.div`
  margin-right: 64px;
  margin-bottom: 32px;
  width: calc(20% - 64px);
  max-width: 100%;
  p {
    font-size: 19px;
    line-height: 1.47;
    letter-spacing: normal;
    margin: 4px 0 0;
  }
  ${(p) => css`
    ${p.theme.media.xl`
      width: calc(20% - 32px);
      margin-right: 32px;
      p {
        font-size: 16px; 
      }
    `}
    ${p.theme.media.desktop`
      width: calc(50% - 32px);
      max-width: 100%;
      min-width: 0;
      margin-right: 32px;
    `}  
    ${p.theme.media.tablet`
      width: calc(50% - 32px);
      max-width: 100%;
      margin-right: 32px;
    `}
    ${p.theme.media.phone`
      width: calc(50% - 16px);
      margin-right: 16px;
      margin-bottom: 24px;
      p {
        font-size: 13px;
      }
    `}
  `}
`;
export const InfosAddress = styled.a`
  display: block;
  margin-top: 4px;
  color: ${(p) => p.theme.grey};
  text-transform: none;
  font-weight: inherit;
  font-size: 19px;
  line-height: 1.47;
  letter-spacing: normal;
  &:hover {
    ${(p) => (p.href ? `
      text-decoration: underline;
    ` : `
      cursor: auto;
    `)}
  }
  &:after {
    display: none;
  }
  ${(p) => css`
    ${p.theme.media.xl`
      font-size: 16px; 
    `}
    ${p.theme.media.tablet`
      ${(p.href ? `
        text-decoration: underline;
      ` : `
        cursor: auto;
      `)}
    `}
    ${p.theme.media.phone`
      font-size: 13px;
    `}
  `}
`;
export const InfosTitle = styled.h3`
  font-size: 16px;
  font-family: ${(p) => p.theme.fontFamilyDisplay};
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  line-height: 1.5;
  ${(p) => css`
    ${p.theme.media.phone`
      font-size: 11px;
    `}
  `}
`;
export const InfosDate = styled.p`
  text-transform: capitalize;
`;
export const AButtonTicketing = styled(AWithButtonStyle)`
  margin-top: 8px;
  text-align: center;
  padding: 12px 16px;
  ${(p) => css`
    ${p.theme.media.phone`
      margin-top: 8px;
      padding: 8px;
      font-size: 12px;
    `}
  `}
`;

export const Prices = styled(InfosElement)`
//  max-width: 400px;
`;
