import React, { memo } from 'react';
import { Link } from 'gatsby';

import EventsTiles from '../../../elements/EventsTiles/EventsTiles';
import Content from '../../../ui/Content';
import getCurrentDateAgendaURL from '../../../../helpers/getCurrentDateAgendaURL';

type Props = {
  events: [],
}

const NextEvents = ({ events }: Props) => (
  <Content>
    <h2 className="h2 mb-8 text-primary">Évènements à venir</h2>
    <EventsTiles events={events} className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8" />
    <div className="text-center mt-8">
      <Link className="inline-block btn btn-secondary" to={getCurrentDateAgendaURL()}>Voir plus d&apos;évènements</Link>
    </div>
  </Content>
);

export default memo(NextEvents);
