import React from 'react';

import Layout from '../components/elements/Layout';
import Links from '../components/screens/Links';

const seo = {
  title: 'Dom’Jazz - Une passion nommée Jazz',
};
const PageLinks = () => (
  <Layout seo={seo} minimalist>
    <Links />
  </Layout>
);
export default PageLinks;
