import React, {
  memo, useCallback, useContext, useEffect, useRef, useState, Suspense,
} from 'react';
import gsap from 'gsap';

import { isBrowser } from '../../../helpers/utils';

import { CalendarContext } from './CalendarProvider';

const ContentfulPictureLiquidDistortion = React.lazy(() => import('../../ui/ContentfulPictureLiquidDistortion'));

const FloatingEventFlyer = () => {
  const refTimeout = useRef(null);
  const refContainer = useRef(null);
  const { state: { hoveredEvent } } = useContext(CalendarContext);
  const [currentEvent, setCurrentEvent] = useState(null);
  const handleMoveImg = useCallback((ev) => {
    const nodeContainer = refContainer.current;
    const eventWidth = document.body.clientWidth / 15;
    if (nodeContainer) {
      gsap.to(nodeContainer, {
        left: (ev.clientX + eventWidth + nodeContainer.clientWidth < document.body.clientWidth)
          ? ev.clientX + eventWidth
          : ev.clientX - nodeContainer.clientWidth - eventWidth,
        top: ev.clientY - nodeContainer.clientHeight / 3,
        ease: 'power4.easeOut',
      });
    }
  }, []);
  useEffect(() => {
    if (hoveredEvent === null) {
      if (!refTimeout.current) {
        refTimeout.current = setTimeout(() => {
          setCurrentEvent(null);
          refTimeout.current = null;
        }, 300);
      }
    } else {
      if (refTimeout.current) {
        clearTimeout(refTimeout.current);
        refTimeout.current = null;
      }
      setCurrentEvent(hoveredEvent);
    }
  }, [hoveredEvent]);
  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('mousemove', handleMoveImg);
    }
    return () => {
      if (isBrowser) {
        window.removeEventListener('mousemove', handleMoveImg);
      }
    };
  }, [handleMoveImg]);
  return (
    <div className="fixed z-50 top-1/2 left-1/2 pointer-events-none" ref={refContainer}>
      {currentEvent?.thumbnail?.gatsbyImageData
        && (
          <Suspense fallback={null}>
            <ContentfulPictureLiquidDistortion
              className="block h-full max-w-[400px] max-h-[400px] object-contain w-[unset]"
              alt={currentEvent.thumbnail.title}
              contentfulGatsbyImage={currentEvent.thumbnail.gatsbyImageData}
            />
          </Suspense>
        )}
    </div>
  );
};

export default memo(FloatingEventFlyer);
