import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import contentfulRichContentDefaultStyle from '../../../styles/contentfulRichContent';

const Container = styled.div`
  padding: 64px;
  max-width: 1920px;
  width: 100%;
  margin: auto;
  ${(p) => css`
    ${p.theme.media.tablet`
      padding: 48px;
    `}
    ${p.theme.media.phone`
      padding: 24px;
    `}
  `}
`;
const Title = styled.h2`
  font-family: ${(p) => p.theme.fontFamilyDisplay};
  font-size: 48px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: ${(p) => p.theme.blueJazz};
  margin-bottom: 48px;
  ${(p) => css`
    ${p.theme.media.xl`
      font-size: 36px;
    `}   
    ${p.theme.media.tablet`
      font-size: 38px;
      margin-bottom: 32px;
    `}
    ${p.theme.media.phone`
      font-size: 30px;
      margin-bottom: 23px;
    `}
  `}
`;
const ContainerInfos = styled.div`
  ${contentfulRichContentDefaultStyle}

  ${(p) => css`
    ${p.theme.media.phone`
      font-size: 17px;
      p {
        margin-bottom: 28px;
        font-size: 17px;
      }
    `}
  `}
`;

type Props = {
  infos: string,
}

const EventInfos = ({ infos }: Props) => (
  <Container>
    <Title>Infos pratiques</Title>
    <ContainerInfos>
      {infos}
    </ContainerInfos>
  </Container>
);

export default memo(EventInfos);
