import React from 'react';

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function debounceFunc() {
    const context = this; const
      // eslint-disable-next-line prefer-rest-params
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
};

export const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function throttleFunc() {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};
export const isBrowser = typeof window !== 'undefined';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function prettyPrice(price) {
  return price === '0' || price === 0 ? 'Entrée libre' : `${price}€`;
}
export function renderEventPrice(price, info) {
  if (!price) return null;
  const formattedPrice = prettyPrice(price);
  return (
    <span>
      {formattedPrice}
      {info ? (<span className="lowercase">{`, ${info}`}</span>) : ''}
    </span>
  );
}

export function lowercaseFrench(str) {
  return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function renderEventStatus(status) {
  return (status && status !== 'Programmé') ? `[${status}] ` : '';
}

export const downloadGeneratedFile = (filename, text) => {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:text/calendar;charset=utf-8,${encodeURIComponent(text)}`,
  );
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const isTouchDevice = () => typeof window !== 'undefined'
  && (('ontouchstart' in window)
    || (navigator.maxTouchPoints > 0)
    || (navigator.msMaxTouchPoints > 0));

export const generateGoogleMapsUrl = (term) => `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(term)}`;

export const scrollIntoViewIfNeeded = (target, opts) => {
  if (target.getBoundingClientRect().bottom > window.innerHeight || target.getBoundingClientRect().top < 0) {
    target.scrollIntoView(opts);
  }
};
