import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

import generateGlobalSchema from '../../../helpers/generateGlobalSchema';

type Props = {
  title: string,
  description: string,
  image: string,
  url: string,
  article: boolean,
  externalImg: boolean,
  pageSchema: [],
};

const SEO = ({
  title, description, image, url, article, externalImg, pageSchema,
}: Props) => {
  const data = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl
          defaultImage: image
        }
      }
    }
  `);
  const {
    defaultTitle, defaultDescription, siteUrl, twitterUsername, titleTemplate, defaultImage,
  } = data.site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    fullTitle: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${url || ''}`,
  };
  if (image) {
    seo.image = externalImg ? image : `${siteUrl}${image}`;
  } else {
    seo.image = `${siteUrl}${defaultImage}`;
  }

  const fullSchema = [...generateGlobalSchema(siteUrl), ...(pageSchema || [])];
  return (
    <>
      <Helmet title={seo.title} titleTemplate={titleTemplate}>
        <html lang="fr" />
        {seo.url && <link rel="canonical" href={seo.url} />}

        <meta name="referrer" content="origin" />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {seo.url && <meta property="og:url" content={seo.url} />}

        <meta property="og:type" content={article ? 'article' : 'website'} />
        {seo.fullTitle && <meta property="og:title" content={seo.fullTitle} />}
        {seo.description && <meta property="og:description" content={seo.description} />}
        {seo.image && <meta property="og:image" content={seo.image} />}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta name="twitter:card" content="summary_large_image" />
        {twitterUsername && <meta name="twitter:site" content={twitterUsername} />}
        {seo.fullTitle && <meta name="twitter:title" content={seo.fullTitle} />}
        {seo.description && <meta name="twitter:description" content={seo.description} />}
        {seo.image && <meta name="twitter:image" content={seo.image} />}

        <script type="application/ld+json">
          {JSON.stringify(fullSchema)}
        </script>
      </Helmet>
    </>
  );
};

export default SEO;
