import React, { memo, useMemo } from 'react';
import { graphql } from 'gatsby';
import dayjs from 'dayjs';

import Layout from '../../elements/Layout';
import EventsTiles from '../../elements/EventsTiles';

import PlaceHeader from './PlaceHeader';

type Props = {
  data: {
    contentfulLieu: {
      name: string,
      slug: string,
    },
    evenements: {
      nodes: []
    }
  }
}
const Place = ({ data }: Props) => {
  const { contentfulLieu, evenements } = data;
  const seo = useMemo(() => ({
    title: `${contentfulLieu.name} - Dom'Jazz`,
    description: `Découvrez tous les dates des évènements Jazz à ${contentfulLieu.name}\
en région Provence-Alpes-Côte d'Azur et bien plus. Une sélection proposée par Dom'Jazz.`,
    url: `/place/${contentfulLieu.slug}/`,
  }), [contentfulLieu]);
  const events = useMemo(() => {
    const dToday = dayjs();
    return (evenements?.nodes || []).reduce((acc, event) => {
      if (dToday.isSameOrBefore(event.event_date)) {
        acc.future.push(event);
      } else {
        acc.past.push(event);
      }
      return acc;
    }, { future: [], past: [] });
  }, [evenements]);
  return (
    <Layout seo={seo} showNavbar light>
      <div className="max-w-[1920px] w-full mx-auto
      px-6 pt-6 pb-12
      sm:px-12 sm:pt-8 sm:pb-20
      md:px-16 md:pt-20 md:pb-40
      "
      >
        <PlaceHeader place={contentfulLieu} />
        <div className="mt-20">
          <h2 className="text-primary text-[30px] sm:text-[38px] md:text-[48px] mb-6 sm:mb-8 md:mb-14 font-display">
            Évènements à venir
          </h2>
          {events.future.length > 0 ? <EventsTiles events={events.future} />
            : <div className="text-purple text-2xl">Pas d&apos;évènements à venir</div>}
        </div>
        <div className="mt-20">
          <h2 className="text-primary text-[30px] sm:text-[38px] md:text-[48px] mb-6 sm:mb-8 md:mb-14 font-display">
            Évènements passés
          </h2>
          {events.past.length > 0 ? <EventsTiles events={events.past} />
            : <div className="text-purple text-2xl">Pas d&apos;évènements passés</div>}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Place($slug: String!) {
    contentfulLieu(slug: {eq: $slug}) {
      id
      address
      name
      slug
      geoAddress {
        lat
        lon
      }
      city {
        id
        name
        slug
      }
    }
    evenements: allContentfulEvenement(filter: {
      place: {
        slug: { eq: $slug }
      }
    }, sort: { order: DESC, fields: event_date }) {
      nodes {
        ...EventInfos
      }
    }
  }
`;

export default memo(Place);
