import React, { memo, useMemo } from 'react';

type Props = {
  className: string,
  width?: string,
  children: any;
}

const Content = ({
  children, className, width,
}: Props) => {
  const styleContent = useMemo(() => ({ maxWidth: width }), [width]);
  return (
    <div className={`px-6 py-8 sm:px-12 md:py-20 ${className || ''}`}>
      <div style={styleContent} className="w-full mx-auto">{children}</div>
    </div>
  );
};

Content.defaultProps = {
  width: '1330px',
};
export default memo(Content);
