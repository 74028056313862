import styled from 'styled-components';
import { setLightness } from 'polished';

import theme from '../../styles/theme';

const ButtonWithLinkStyle = styled.button`
  color: ${(p) => p.color};
  font-size: 16px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  transition: 0.2s color ease-in;
  padding: 0;
  &:after {
    position: absolute;
    content: "";
    bottom: -6px;
    width: 100%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: currentColor;
  }
  &:hover {
    background: transparent;
    color: ${(p) => p.hoverColor || setLightness(0.6, p.color)};
  }
`;
ButtonWithLinkStyle.defaultProps = {
  color: theme.blueJazz,
};

export const LinkButtonWhite = styled(ButtonWithLinkStyle)``;
LinkButtonWhite.defaultProps = {
  color: 'white',
  hoverColor: theme.lightGrey,
};

export default ButtonWithLinkStyle;
