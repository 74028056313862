import React, { memo, useMemo } from 'react';
import { Link } from 'gatsby';
import dayjs from 'dayjs';

import { iconArrowLeft, iconArrowRight } from '../../ui/Icons';

type Props = {
  currentYear: string,
  currentMonth: string,
  pathNextMonth: string,
  pathPreviousMonth: string,
  pathToday: string,
}

const MonthSelector = ({
  currentMonth, currentYear, pathNextMonth, pathPreviousMonth, pathToday,
}: Props) => {
  const currentDate = useMemo(() => dayjs(`${currentYear}-${currentMonth}-01`), [currentMonth, currentYear]);
  return (
    <div className="flex flex-row items-center text-primary">
      <div className="flex flex-row items-center">
        {pathPreviousMonth ? (
          <Link className="text-[22px] md:text-2xl leading-[0] text-inherit hover:text-black" to={pathPreviousMonth}>{iconArrowLeft}</Link>
        ) : <div className="invisible text-2xl">{iconArrowLeft}</div>}
        <div className="
          text-[19px] px-3 w-[160px] leading-normal text-center capitalize box-content
          lg:px-6 lg:text-[21px] lg:w-[175px]
          xl:px-8 xl:text-2xl xl:w-[200px]
        "
        >
          {currentDate.format('MMMM YYYY')}
        </div>
        {pathNextMonth ? <Link className="text-[22px] md:text-2xl leading-[0] text-inherit hover:text-black" to={pathNextMonth}>{iconArrowRight}</Link>
          : <div className="invisible text-2xl">{iconArrowRight}</div>}
      </div>
      {pathToday && (
        <div className="text-center ml-8">
          <Link className="btn btn-secondary inline-flex h-9 px-4 justify-center inline-flex flex-col " to={pathToday}>Aujourd&apos;hui</Link>
        </div>
      )}
    </div>
  );
};

export default memo(MonthSelector);
