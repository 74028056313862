import React, {
  memo, useRef, Suspense, useMemo,
} from 'react';
import {
  Canvas, extend, useFrame, useLoader, useThree,
} from '@react-three/fiber';
import { shaderMaterial } from '@react-three/drei';
import {
  Color, Texture, TextureLoader, LinearFilter,
} from 'three';
import glsl from 'babel-plugin-glsl/macro';

import theme from '../../styles/theme';

import ContentfulPicture from './ContentfulPicture';

type Props = {
  className?: string,
  alt: string,
  contentfulGatsbyImage: {
    images: {
      fallback: {
        src: string,
      }
    }
  }
}

const WaveShaderMaterial = shaderMaterial(
  {
    uTime: 0,
    uColor: new Color(0.0, 0.0, 0.0),
    uTexture: new Texture(),
  },
  glsl`
    precision mediump float;
  
    varying vec2 vUv;
    varying float vWave;
    uniform float uTime;
    
    #pragma glslify: snoise3 = require(glsl-noise/simplex/3d);
    
    void main() {
      vUv = uv;
      
      vec3 pos = position;
      float noiseFreq = 2.5;
      float noiseAmp = 0.2;
      vec3 noisePos = vec3(pos.x * noiseFreq + uTime * 0.2, pos.y, pos.z);
      pos.z += snoise3(noisePos) * noiseAmp;
      vWave = pos.z;
      
      gl_Position = projectionMatrix * modelViewMatrix * vec4
      (pos, 1.0);
    }
  `,
  glsl`
    precision mediump float;
    
    uniform vec3 uColor;
    uniform float uTime;
    uniform sampler2D uTexture;
    
    varying vec2 vUv;
    varying float vWave;

    void main() {
      float wave = vWave * 0.1;
      vec3 texture = texture2D(uTexture, vUv + wave).rgb;
      gl_FragColor = vec4(texture, 1.0);
    }
  `,
);

extend({ WaveShaderMaterial });

type WaveProps = {
  srcImg: string,
}
const Wave = ({ srcImg }: WaveProps) => {
  const ref = useRef(null);
  const { viewport } = useThree();
  useFrame(({ clock }) => {
    ref.current.uTime = clock.getElapsedTime();
  });
  const [image] = useLoader(TextureLoader, [srcImg]);
  image.minFilter = LinearFilter;
  return (
    <mesh scale={[viewport.width * 0.98, viewport.height * 0.98, 1]}>
      <planeBufferGeometry args={[1, 1, 4, 4]} />
      <waveShaderMaterial ref={ref} uColor={theme.blueJazz} uTexture={image} />
    </mesh>
  );
};

const ContentfulPictureLiquidDistortion = ({ alt, contentfulGatsbyImage, className }: Props) => {
  const image = useMemo(() => contentfulGatsbyImage?.images?.fallback?.src, [contentfulGatsbyImage]);
  return (
    <div className="relative">
      <ContentfulPicture
        className={`${className || ''} opacity-0`}
        alt={alt}
        contentfulGatsbyImage={contentfulGatsbyImage}
      />
      {image
        && (
        <div className="absolute top-0 left-0 z-50 w-full h-full">
          <Canvas camera={{ fov: 10 }} linear>
            <Suspense fallback={false}>
              <Wave srcImg={image} />
            </Suspense>
          </Canvas>
        </div>
        )}
    </div>
  );
};

export default memo(ContentfulPictureLiquidDistortion);
