import React, { memo } from 'react';

import EventListElement from '../EventListElement/EventListElement';
import Anchor from '../../ui/Anchor';

type Props = {
  events: [{
    date: {},
    dateFormatted: string,
    dayEvents: [],
    isToday: boolean,
    isCurrentMonth: boolean,
  }],
}

const EventsList = ({ events }: Props) => (
  <div>
    {events.map(({
      events: dayEvents, date, dateFormatted, isToday,
    }) => {
      if ((!dayEvents || dayEvents.length === 0) && !isToday) {
        return null;
      }
      return (
        <div className="border-b border-light-grey" key={dateFormatted}>
          <div className="max-w-[1920px] w-full mx-auto pb-6 flex flex-row">
            <div>
              {isToday && <Anchor anchorId="today" />}
              <div className={`
                sticky z-20 text-left ${isToday ? 'font-medium' : 'font-normal'}
                pt-6 pl-4 top-[85px] w-[96px]
                sm:pl-12 sm:w-[160px] sm:top-[97px]
                md:top-[113px]
                lg:top-[84px] lg:w-[233px]
                xl:w-[275px] xl:pt-8 lg:pl-16
                `}
              >
                <h3 className="text-2xl sm:text-[32px] md:text-[36px] xl:text-[42px] leading-tight text-primary">
                  {date.format('DD')}
                </h3>
                <p className="text-sm sm:text-[17px] md:text-[19px] xl:text-[21px] leading-[1.43] capitalize">
                  {date.format('dddd')}
                </p>
                {isToday && <p className="mt-3 text-[12px] sm:text-[17px] leading-[1.64] font-display text-primary">Aujourd&apos;hui</p>}
              </div>
            </div>
            <div className={`${isToday ? 'border-primary' : 'border-transparent'}
              pr-4 pl-3 w-full mt-6
              sm:pr-12 xl:mt-8 xl:pl-4 xl:pl-16
              border-l-[5px]
            `}
            >
              {dayEvents.length ? dayEvents.map((dayEvent) => (
                <EventListElement
                  className="max-w-[1158px] mb-7 sm:mb-4 last:mb-0"
                  key={dayEvent.slug}
                  event={dayEvent}
                />
              )) : <div className="max-w-[1040px] mx-auto text-base sm:text-2xl text-grey">Pas d&apos;évènement aujourd&apos;hui</div>}
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

export default memo(EventsList);
