import React, { memo, useMemo } from 'react';

import Layout from '../../elements/Layout';

import Hero from './blocks/Hero';
import Association from './blocks/Association';
import NextEvents from './blocks/NextEvents';
import WeGotNewsletter from './blocks/WeGotNewsletter';

const seo = {
  title: 'Dom’Jazz - Une passion nommée Jazz',
};
type Props = {
  data: {
    nextEvents: [],
  },
}
const Home = ({ data }: Props) => {
  const nextEvents = useMemo(() => (data.nextEvents.edges?.length > 0
    ? data.nextEvents.edges.map(({ node }) => node) : null), [data.nextEvents]);
  return (
    <Layout seo={seo} themeNewsletterEmail="dark">
      <Hero />
      {nextEvents && <NextEvents events={nextEvents} />}
      <WeGotNewsletter />
      <Association />
      {/* <Adhesions /> */}
    </Layout>
  );
};
export default memo(Home);
