import React, { memo, useCallback, useState } from 'react';
import { Link } from 'gatsby';

import Logo from '../../ui/Logo';
import getCurrentDateAgendaURL from '../../../helpers/getCurrentDateAgendaURL';
import { iconBurger } from '../../ui/Icons';

import MobileMenu from './MobileMenu';

type Props = {
  light: boolean,
}
const Header = ({ light }: Props) => {
  const [openNavMenu, setOpenNavMenu] = useState(false);
  const handleClickBurger = useCallback(() => setOpenNavMenu(true), []);
  const handleCloseMenu = useCallback(() => setOpenNavMenu(false), []);
  return (
    <div className={light ? 'bg-white text-primary border-b border-light-grey' : 'bg-primary text-white'}>
      <div className="h-[80px] px-6 sm:px-12 lg:px-16 flex flex-row items-center relative max-w-[1920px] w-full mx-auto">
        <Link className="inline-flex flex-row items-center" to="/">
          <Logo className={`h-12 w-auto ${light ? 'text-primary' : 'text-white'}`} />
        </Link>
        <nav className="flex-1 hidden flex-row justify-end items-center md:flex">
          <Link
            className="font-display text-base leading-normal text-inherit"
            to="/"
          >
            L&apos;Association
          </Link>
          <Link
            className="ml-8 font-display text-base leading-normal text-inherit"
            to="/places/"
          >
            Les Lieux
          </Link>
          <Link className="btn btn-secondary ml-8" to={getCurrentDateAgendaURL()}>L&apos;Agenda</Link>
        </nav>
        <button type="button" className="md:hidden flex-1 flex flex-row justify-end text-2xl" onClick={handleClickBurger}>
          {iconBurger}
        </button>
        <MobileMenu open={openNavMenu} onClose={handleCloseMenu} />
      </div>
    </div>
  );
};

export default memo(Header);
