import React, { memo } from 'react';
import styled from 'styled-components';

import BlankLink from '../../ui/BlankLink';

export const LinkBreadcrumb = styled(BlankLink)`
  color: ${(p) => p.theme.black};
  font-size: 14px;
  line-height: 1.69;
  font-weight: normal;
  position: relative;
  display: inline-block;
  text-transform: capitalize;
  &:hover {
    text-decoration: underline;
  }
  & + & {
    margin-left: 16px;
    &:before {
      content: '/';
      position: absolute;
      line-height: 0;
      top: 50%;
      left: -10px;
      transform: translateY(-50%);
    }
  }
  ${(p) => p.theme.media.tablet`
    text-decoration: underline;
  `}
`;

type Props = {
  links: [{
    label: string,
    url: string
  }]
}

const Breadcrumb = ({ links }: Props) => (
  <div>
    {links.map((link) => <LinkBreadcrumb key={link.label} to={link.url}>{link.label}</LinkBreadcrumb>)}
  </div>
);

export default memo(Breadcrumb);
