import dayjs from 'dayjs';

const schemaStatus = {
  Programmé: 'https://schema.org/EventScheduled',
  Annulé: 'https://schema.org/EventCancelled',
  Reporté: 'https://schema.org/EventPostponed',
};

type Props = {
  title: string,
  description: string,
  event_date: string,
  ticketing_url: string,
  createdAt: string,
  entry_hours: {
    entryHours: [{
      startTime: string,
      endTime: string,
    }],
  },
  thumbnail: {
    title: string,
    gatsbyImageData: {},
    file: {
      url: string,
    }
  },
  artists: [{
    name: string,
    slug: string,
  }],
  place: {
    name: string,
    address: string,
    city: {
      name: string,
    }
  },
  prices: {
    prices: [{
      price: string,
      info: string,
    }],
  }
}

export default function generateEventSchema(event: Props, metaDescription) {
  const {
    title, ticketing_url: ticketingUrl, place, event_date: eventDate, artists, thumbnail, prices, createdAt, status,
  } = event;
  const eventStatus = schemaStatus[status] || schemaStatus['Programmé'];
  const entryHour = event.entry_hours.entryHours ? event.entry_hours.entryHours[0] : null;
  const startDate = entryHour ? entryHour.startTime : eventDate;
  const endDate = entryHour ? entryHour.endTime : eventDate;
  const eventSchema = {
    '@context': 'https://schema.org',
    '@type': 'MusicEvent',
    name: title,
    startDate,
    endDate,
    description: metaDescription,
    eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
    eventStatus,
    image: thumbnail?.file?.url && `https:${thumbnail.file.url}`,
    location: place && {
      '@type': 'Place',
      name: place.name,
      address: place.address,
    },
    organizer: place && {
      '@type': 'Organization',
      name: place.name,
    },
    offers: prices?.prices?.length > 0 ? prices.prices.map((data) => ({
      '@type': 'Offer',
      price: data.price,
      priceCurrency: 'EUR',
      name: data.info,
      url: ticketingUrl,
      validFrom: createdAt,
      availability: 'https://schema.org/LimitedAvailability',
    })) : {
      '@type': 'Offer',
      url: ticketingUrl,
      validFrom: createdAt,
      availability: 'https://schema.org/LimitedAvailability',
    },
    performer: (artists || []).map((artist) => ({
      '@type': 'MusicGroup',
      name: artist.name,
      sameAs: `https://www.domjazz.com/artist/${artist.slug}`,
    })),
  };
  const breadcrumb = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [],
  };
  const dEventDate = dayjs(eventDate);
  breadcrumb.itemListElement.push({
    '@type': 'ListItem',
    position: 1,
    name: dEventDate.format('MMM YYYY'),
    item: `https://www.domjazz.com/agenda/${dEventDate.format('YYYY/M')}`,
  });
  if (place?.city?.slug) {
    breadcrumb.itemListElement.push({
      '@type': 'ListItem',
      position: 1,
      name: place.city.name,
      item: `https://www.domjazz.com/agenda/${dEventDate.format('YYYY/M')}?city=${place.city.slug}`,
    });
  }
  return [breadcrumb, eventSchema];
}
